import styled from 'styled-components';

export const Type15 = styled.p`
  font: 300 15px/19px Poppins;
  letter-spacing: 0.6px;
  margin: 0;
  padding: 0;
`;

export const Type15Light = styled.p`
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.6px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  color: #0d0d0d;
`;

export const Type14Medium = styled.p`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.56px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #0d0d0d;
`;

export const Type14SemiBold2B = styled.p`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #2b2b2b;
`;

export const Type14Regular2B = styled.p`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  color: #2b2b2b;
`;

export const Type18SemiBoldBlack = styled.p`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
`;
