/**
 * We slice the last segment of the url and path in order to use
 * the tabName as the param for identification.
 * @param u the route match url or path
 */
export const sliceUrl = (u: string): string => u.slice(0, u.lastIndexOf('/')).trim();

/**
 * Make sure url is valid
 * Ex: url = 'www.site.com'
 * Returns 'http://www.site.com'
 * @param url
 */
export const getValidUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};

/**
 * Deep copy object.
 * @param obj the object to copy
 * @param T type parameter
 */
export const copyObj = <T extends {}>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Converts a string to title case
 * @param str a string to convert, can be a single word or a string of words
 */
export const titleCaser = (str: string): string => {
  const results: Array<string> = [];
  const temp = str.split(' ');

  temp.forEach((word: string, idx: number): void => {
    results.push(word.slice(0, 1).toUpperCase().concat(word.slice(1).toLowerCase()));
  });

  return results.join(' ');
};

/**
 * Fisher-Yates shuffle by way of Mike Bostock https://bost.ocks.org/mike/shuffle/
 * @param array the array to be shuffled
 * @EX : shuffle<Interface[]>(arrayName)
 */
export const shuffle = <T extends unknown[]>(array: T): T => {
  var m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
};

/**
 * Combines address1 and address2 into a single string
 * @param a1 shopAddress1
 * @param a2 shopAddress2
 * returns                  Ex: 61 N. Ocean Ave., Ste 101
 */
export const streetAddress = (a1: string, a2: string): string => (a2 ? a1.concat(', ', a2) : a1);
