import * as React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { IListingMemberShop } from '../../types/types';
import MarkerListing from './MarkerListing';

export interface MapBoxProps {
  listings: Array<IListingMemberShop>;
  shopSelected: string | null;
  setShopSelected: React.Dispatch<React.SetStateAction<string | null>>;
  shopHovered: string | null;
  showError: boolean;
}

const MapBox: React.FC<MapBoxProps> = ({
  listings,
  shopSelected,
  setShopSelected,
  shopHovered,
  showError,
}) => {
  const errorPosition = { lat: 37.09024, lng: -95.712891 }; // central USA latlng
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [mapLoaded, setMapLoaded] = React.useState<boolean>(false);

  /**
   * Map Loaded so save it
   */
  const onLoad = React.useCallback(
    function callback(map) {
      if (map) {
        const bounds = new window.google.maps.LatLngBounds();
        if (showError) bounds.extend(errorPosition);
        map.fitBounds(bounds);
        setMap(map);
        setMapLoaded(true);
      }
    },
    [showError, errorPosition],
  );

  React.useEffect(() => {
    if (map && showError) map.setZoom(4);
  }, [showError, map]);

  /**
   * Leave page so clean map
   */
  const onUnmount = React.useCallback(function callback() {
    setMap(null);
    setMapLoaded(false);
  }, []);

  /**
   * Location of listing changed so update bounds
   */
  const locationChanged = React.useCallback(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      listings.forEach((listing) => {
        if (listing.position) {
          bounds.extend(listing.position);
        }
      });
      map.fitBounds(bounds);
    }
  }, [listings, map]);

  /**
   * Marker Clicked so set that as the the selected marker
   * @param shopCode
   */
  const markerClicked = React.useCallback(
    (shopCode: string) => {
      if (shopSelected !== shopCode) {
        // New marker selected
        setShopSelected(shopCode);
      } else {
        // Same marker clicked so toggle it off
        setShopSelected(null);
      }
    },
    [setShopSelected, shopSelected],
  );

  /**
   * Marker Info Closed clicked so unselect marker
   */
  const markerInfoClicked = React.useCallback(() => {
    setShopSelected(null);
  }, [setShopSelected]);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={12}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={() => markerInfoClicked()}
    >
      {listings &&
        listings.map((listing) => (
          <MarkerListing
            key={listing.shopCode}
            mapLoaded={mapLoaded}
            isHovered={listing.shopCode === shopHovered}
            isSelected={listing.shopCode === shopSelected}
            onClick={markerClicked}
            onCloseClick={markerInfoClicked}
            listing={listing}
            onLocationChange={locationChanged}
          />
        ))}
    </GoogleMap>
  );
};

export default MapBox;
