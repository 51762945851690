import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Hidden } from '@material-ui/core';
import Linkedin from '../../../assets/Linkedin.png';
import Facebook from '../../../assets/Facebook.png';
import Twitter from '../../../assets/Twitter.png';
import LogoLink from '../../links/LogoLink';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AdvertiseWithUs from './AdvertiseWithUs';
import GALink from '../../links/GALink';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    alignSelf: 'center',
    padding: '0',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    [theme.breakpoints.up('sm')]: {
      height: '150px',
    },

  },
  footerContent: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 100px',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1400px',
    },
  },
  linksWrapper: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexFlow: 'row nowrap',
      maxWidth: '82px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  socialMediaIcon: {
    height: 18,
  },
}));

function Copyright() {
  return (
    <>
      <Typography variant="caption">
        &copy;{new Date().getFullYear()} Bloomnet Technologies. All Rights Reserved.
      </Typography>
    </>
  );
}

const SocialMedia: React.FC = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.linksWrapper}>
      <GALink
        className={classes.socialMediaIcon}
        eventLabel="Bloomnet LinkedIn"
        to="https://sg.linkedin.com/company/bloomnetinc"
      >
        <img src={Linkedin} alt="Linkedin Logo" />
      </GALink>

      <GALink
        className={classes.socialMediaIcon}
        eventLabel="Bloomnet Facebook Link"
        to="https://www.facebook.com/BloomNet.net/"
      >
        <img src={Facebook} alt="Facebook Logo" />
      </GALink>

      <GALink
        className={classes.socialMediaIcon}
        eventLabel="Bloomnet Twitter Link"
        to="https://twitter.com/bloomnet"
      >
        <img src={Twitter} alt="Twitter Logo" />
      </GALink>
    </div>
  );
};

const SiteFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Hidden only="xs">
        <div className={classes.footerContent}>
          <Grid container spacing={4} direction="row" justify="space-between" alignItems="center">
            <Grid item container xs={6} direction="column">
              <Grid
                item
                container
                xs={12}
                direction="row"
                justify="flex-start"
                alignItems="center"
                wrap="wrap"
              >
                <Grid item xs={6} className={classes.logo}>
                  <LogoLink />
                </Grid>
                <Grid item xs={6}>
                  <SocialMedia />
                </Grid>
              </Grid>
              <Hidden only="sm">
                <Grid item xs={12}>
                  <Copyright />
                </Grid>
              </Hidden>
            </Grid>

            <Grid item xs={6}>
              <AdvertiseWithUs />
            </Grid>
          </Grid>
        </div>
      </Hidden>
      <Hidden mdUp>
        <Copyright />
      </Hidden>
    </footer>
  );
};

export default SiteFooter;
