import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import HomeContent from './content/HomeContent';
import SelectedFloristContent from './content/SelectedFloristContent';
import FloristsHeaderContent from './content/FloristHeaderContent';

const useStyles = makeStyles((theme) => ({
  appbar: {
    height: '80px',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      height: '65px',
    },
  },
}));

const Header: React.FC<HeaderProps> = ({ headerContent }) => {
  const classes = useStyles();

  const HEADERS: HeadersObject = {
    home: HomeContent,
    florists: FloristsHeaderContent,
    selected: SelectedFloristContent,
  };
  const Content = HEADERS[headerContent];

  return (
    <AppBar className={classes.appbar} position="static" color="transparent">
      <Content />
    </AppBar>
  );
};

export default Header;

export interface HeaderProps {
  headerContent: string;
}

type HeadersObject = {
  [key: string]: React.FC;
};
