import * as React from 'react';
import { IListingMemberShop } from '../../types/types';
import api from '../../utilities/api';
import { InfoWindow, Marker } from '@react-google-maps/api';
import MarkerCard from './card/MarkerCard';
import markerPin from '../../assets/marker-pin.png';
import markerPinHovered from '../../assets/marker-pin-hovered.png';
import markerFlower from '../../assets/marker-flower.png';
import markerFlowerHovered from '../../assets/marker-flower-hovered.png';
import axios from 'axios';

export interface MarkerListingProps {
  mapLoaded: boolean;
  isSelected: boolean;
  isHovered: boolean;
  listing: IListingMemberShop;
  onClick?: (shopCode: string) => void;
  onCloseClick?: (shopCode: string) => void;
  onLocationChange?: (shopCode: string) => void;
}

const MarkerListing: React.FC<MarkerListingProps> = ({
  mapLoaded,
  isSelected,
  isHovered,
  listing,
  onClick,
  onCloseClick,
  onLocationChange,
}) => {
  const [position, setPosition] = React.useState<google.maps.LatLng | null>(null);
  const positionChangeCount = React.useRef(0);
  const {
    shopCode,
    listingAdSize,
	listingCitySponsorAd,
    listingBid,
    shopAddress1,
    shopCity,
    shopState,
    shopZip,
  } = listing;

  const [isPromo, setIsPromo] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!listingCitySponsorAd) return;
    else setIsPromo(true);
  }, [listingAdSize, listingBid]);

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    // Listings dont have Lat or Lng so use api to get information and update marker
    api
      .lookupLocation(`${shopAddress1} ${shopCity},${shopState} ${shopZip}`)
      .then((response) => {
        let location;
        if (response.data.status === 'OK') {
          location = response.data.results[0].geometry.location;

          // Update Position of marker and set listings position
          setPosition(location);
        } else {
          setPosition(null);
        }
      })
      .catch(() => {
        setPosition(null);
      });

    return () => source.cancel('Cancelling API call');
  }, [shopAddress1, shopCity, shopCode, shopState, shopZip]);

  React.useEffect(() => {
    if (mapLoaded && position) {
      listing.position = position;
      if (onLocationChange && positionChangeCount.current <= 0) {
        onLocationChange(shopCode);
      }
      positionChangeCount.current++;
    }
  }, [mapLoaded, listing.position, position, shopCode, onLocationChange]);

  // Click handlers to pass back up to parent if function provided
  const onMarkerClick = () => (onClick || Function)(listing.shopCode);
  const onMarkerInfoClose = () => (onCloseClick || Function)(listing.shopCode);

  return position !== null ? (
    <Marker
      key={shopCode}
      position={position}
      zIndex={isHovered || isSelected ? 3 : isPromo ? 2 : 1}
      icon={
        isPromo
          ? {
              url: isHovered || isSelected ? markerFlowerHovered : markerFlower,
              anchor: new google.maps.Point(35, 36), // Middle of Icon 70x72
            }
          : {
              url: isHovered || isSelected ? markerPinHovered : markerPin,
              anchor: new google.maps.Point(25, 25), // Middle of Icon 50x50
            }
      }
      onClick={onMarkerClick}
    >
      {isSelected ? (
        <InfoWindow onCloseClick={onMarkerInfoClose}>
          <MarkerCard listing={listing} />
        </InfoWindow>
      ) : null}
    </Marker>
  ) : null;
};

export default React.memo(MarkerListing);
