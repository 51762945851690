import * as React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { ShoppingBagIcon } from '../../../components/index';
import { ISignatureProduct } from '../../../types/types';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { baseImageUrl } from '../../index';
import { format } from 'date-fns';
import { ColWrapper } from '../../../components/layout/wrappers';
import { shuffle, getValidUrl } from '../../../utilities/javascripts';
import { IListingMemberShop, ILocInfo, IRouterState } from '../../../types/types';
import { makeStyles } from '@material-ui/core';
import GALink from '../../../components/links/GALink';
import { internalNavButtonGA } from '../../../utilities/googleAnalytics';

export interface SignatureArrangementsProps {
  signatureArrangements: Array<ISignatureProduct>;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '100px 0 0 0',
  },
  link: {
    textDecoration: 'none',
  },
  whiteButton: {
    background: '#ffffff',
    width: '149px',
    height: '52px',
    font: '500 15px/23px Poppins',
    textTransform: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    '&:hover': {
      background: '#ffffff',
      boxShadow: 'none',
    },
    '&:active': {
      background: '#ffffff',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  button: {
    width: '265px',
    height: '44px',
    font: '500 12px/18px Poppins',
    borderWidth: '2px',
    borderRadius: '5px',
    textTransform: 'none',
    padding: '5px 10px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const NextArrow = (props: any) => {
  return (
    <div style={{ position: 'absolute', top: '50%', right: '-25px', cursor: 'pointer' }}>
      <NavigateNextIcon fontSize="large" onClick={props.onClick} />
    </div>
  );
};

const PrevArrow = (props: any) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '-25px',
        cursor: 'pointer',
      }}
    >
      <NavigateBeforeIcon fontSize="large" onClick={props.onClick} />
    </div>
  );
};

const SignatureCard: React.FC<{
  productImage: string;
  productName: string | null;
  productPrice: string | null;
  shopWebsite: string | null;
  shopCode : string | null;
  listing: IListingMemberShop;
  loc: ILocInfo;
}> = ({ productImage, productName, productPrice, shopWebsite, shopCode, listing, loc, }): React.ReactElement => {
  const classes = useStyles();
  const website = !!shopWebsite ? getValidUrl(shopWebsite) : '#';
  productName = productName === "null" ? "" : productName;
  productPrice = productPrice === "$null" ? "" : productPrice;
  productPrice = productPrice === "$0.00" ? "" : productPrice;
  
  const handleViewOnMapBtn = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    internalNavButtonGA(event.currentTarget.id);
  };
  
  const buildLocalParams = () => {
    const searchParams = new URLSearchParams();
    const date = new Date();

    searchParams.append('city', loc.city.toUpperCase());
    searchParams.append('state', loc.state_long.toUpperCase());
    searchParams.append('sc', loc.state_short);
    searchParams.append('zip', loc.postal_code);
    searchParams.append('date', format(date, 'yyyy-MM-dd'));

    return searchParams;
  };

  const params = buildLocalParams();
  
  const routerState: IRouterState = {
	listingState: listing,
    headerState: {
      cityName: undefined,
      facilityName: undefined,
      resultsText: 'Your Selected Florist',
    },
  };
  
  if(shopWebsite != null){
	  return (
		<Card>
		  <ImageWrapper>
			<BtnWrapper className="button-wrapper">
			  <GALink
				className={classes.link}
				eventLabel={`Signature Product: ${website}`}
				to={getValidUrl(website)}
			  >
				<Button
				  className={classes.whiteButton}
				  variant="contained"
				  startIcon={<ShoppingBagIcon color="#000000" />}
				>
				  Shop Now
				</Button>
			  </GALink>
			</BtnWrapper>
			<CardImage
			  className="product-image"
			  src={baseImageUrl.concat(productImage)}
			  alt={productName}
			/>
		  </ImageWrapper>
		  <CardTitle>{productName}</CardTitle>
		  <CardPrice>{productPrice}</CardPrice>
		  <GALink
			className={classes.link}
			eventLabel={`Signature Product: ${website}`}
			to={getValidUrl(website)}
		  >
			<Button
			  className={classes.button}
			  fullWidth
			  disableElevation
			  color="primary"
			  variant="contained"
			  startIcon={<ShoppingBagIcon color="#ffffff" />}
			>
			  Shop Now
			</Button>
		  </GALink>
		</Card>
	  );
  }else{
	return (
		<Card>
		  <ImageWrapper>
			<BtnWrapper className="button-wrapper">
				<Button
				  className={classes.whiteButton}
				  variant="contained"
				  onClick={handleViewOnMapBtn}
				  component={RouterLink}
				  to={
					{
					  pathname: `/our-florists`,
					  search: params.toString(),
					  state: routerState,
					}
				  }			  
				>
				  View on Map
				</Button>
			</BtnWrapper>
			<CardImage
			  className="product-image"
			  src={baseImageUrl.concat(productImage)}
			  alt={productName}
			/>
		  </ImageWrapper>
		  <CardTitle>{productName}</CardTitle>
		  <CardPrice>{productPrice}</CardPrice>
			<Button
			  className={classes.button}
			  fullWidth
			  disableElevation
			  color="primary"
			  variant="contained"
			  onClick={handleViewOnMapBtn}
			  component={RouterLink}
			  to={
					{
					  pathname: `/our-florists/${shopCode}`,
					}
				}			  
			>
			  View Details
			</Button>
		</Card>
	  );  
  }
};

const SignatureArrangements: React.FC<SignatureArrangementsProps> = ({
  signatureArrangements,
}): React.ReactElement => {
  const classes = useStyles();
  const shuffled = !!signatureArrangements
    ? shuffle<ISignatureProduct[]>(signatureArrangements)
    : null;

  const length = !!shuffled ? shuffled.length : 0;
  const maxSlides = (x: number, l: number): number => {
    if (length < x) return l;
    else return x;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: maxSlides(3, length),
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: maxSlides(2, length),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <ColWrapper className={classes.wrapper}>
      <SliderContainer>
        <Slider {...settings}>
          {!!shuffled
            ? shuffled.map((product) => <SignatureCard key={product.shopCode} {...product} />)
            : null}
        </Slider>
      </SliderContainer>
    </ColWrapper>
  );
};

export default SignatureArrangements;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SecTitle = styled.h2`
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 54px 0;
  text-align: center;
`;

const Card = styled.div`
  width: 265px;
  display: grid;
  grid-template-columns: [card] auto;
  grid-template-rows: [img] auto [title] 28px [price] 28px [shop-now] auto;
  justify-items: center;
  align-items: start;
  row-gap: 6px;
  margin: auto;

  @media only screen and (min-width: 768px) {
    grid-template-columns: [card] auto;
    grid-template-rows: [img] auto [title] 28px [price] 28px;
    justify-items: center;
    align-items: start;
    row-gap: 6px;
    margin: auto;
  }
`;

const ImageWrapper = styled.div`
  grid-column: card;
  grid-row: img;
  display: grid;
  grid-template-columns: [only] auto;
  grid-template-rows: [only] auto;
  @media only screen and (min-width: 768px) {
    &:hover .product-image {
      filter: brightness(37%);
    }
    &:hover .button-wrapper {
      opacity: 1;
    }
  }
`;

const CardImage = styled.img`
  grid-column: only;
  grid-row: only;
  min-height: 265px;
  max-height: 265px;
  min-width: 200px;
  max-width: 200px;

  @media only screen and (min-width: 768px) {
    filter: brightness(100%);
    transition: filter 200ms ease-out;
  }
`;

/**
 * This button is only displayed when the screen is 'desktop' sized.
 */
const BtnWrapper = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
    opacity: 0;
    transition: opacity 200ms ease-out;
    grid-column: only;
    grid-row: only;
    align-self: center;
    justify-self: center;
    z-index: 100;
  }
`;

const CardTitle = styled.p`
  padding: 0;
  margin: 0;
  grid-column: card;
  grid-row: title;
`;

const CardPrice = styled.p`
  padding: 0;
  margin: 0;
  grid-column: card;
  grid-row: price;
`;
