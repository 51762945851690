import * as React from 'react';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import {
  ILocInfo,
  ISignatureProduct,
  isErrorMessage,
  ICustomProduct,
  CallGetListingsProps,
  FetchLocationProps,
  ILocation,
  IListingMemberShop,
} from '../../../types/types';
import { ColWrapper } from '../../../components/layout/wrappers';
import NoResults from './NoResults';
import Results from './Results';
import api from '../../../utilities/api';

export interface IPopularSection {}

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0',
    [theme.breakpoints.up('sm')]: {
      margin: '0 .25em 0 0',
    },
  },
  location: {
    margin: '0',
    color: theme.palette.primary.main,
  },
  typographyPadding: {
    alignSelf: 'center',
    maxWidth: '80%',
  },
}));

const PopularSection: React.FC<IPopularSection> = (): React.ReactElement | null => {
  const classes = useStyles();

  // Hide Component if geolocation not enabled
  const [hideSection, setHideSection] = React.useState(true);

  // Loading State
  const [isCoordsLoading, setIsCoordsLoading] = React.useState<boolean>(true);
  const [isLocLoading, setIsLocLoading] = React.useState<boolean>(true);
  const [isPopLocationLoading, setIsPopLocationLoading] = React.useState<boolean>(true);
  const [isLocalShopsLoading, setIsLocalShopsLoading] = React.useState<boolean>(true);

  // Error Handling
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (!!errorMessage) {
      setIsCoordsLoading(false);
      setIsLocLoading(false);
      setIsPopLocationLoading(false);
      setIsLocalShopsLoading(false);
    }
  }, [errorMessage]);

  // Get the user's geolocation data.
  const [coords, setCoords] = React.useState<string | null>(null);
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchCoords = new Promise((resolve, reject) => {
      api.getLatLng().then(
        (results) => {
          const { lat, lng } = results.data.location;
          const locStr: string = lat.toString().concat(',', lng.toString());
          resolve(locStr);
        },
        (err) => {
          reject(new Error(`Code ${err.code}: ${err.message}`));
        },
      );
    });

    const success = (results: unknown): void => {
      setCoords(results as string);
      setIsCoordsLoading(false);
      setHideSection(false);
    };
    const failure = (err: Error): void => {
      setErrorMessage(err.toString());
      setIsCoordsLoading(false);
      setHideSection(true);
    };

    fetchCoords.then(success, failure);

    return () => source.cancel('Cancelling API call');
  }, []);

  // Convert geolocation data to location info.
  const [loc, setLoc] = React.useState<ILocInfo>({
    city: '',
    state_long: '',
    state_short: '',
    postal_code: '',
  });

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchLocation = ({ coords, setLoc, setErrorMessage }: FetchLocationProps) => {
      api
        .getUsersCityStateZip(coords)
        .then((results) => {
          const data = results.data.results[0].address_components;
          let newLocation: ILocInfo = {
            city: '',
            state_long: '',
            state_short: '',
            postal_code: '',
          };
          data.forEach((d: any) => {
            if (d.types.includes('locality')) {
              newLocation = { ...newLocation, city: d.long_name };
            }
            if (d.types.includes('administrative_area_level_1')) {
              newLocation = { ...newLocation, state_long: d.long_name, state_short: d.short_name };
            }
            if (d.types.includes('postal_code')) {
              newLocation = { ...newLocation, postal_code: d.long_name };
            }
          });
          setLoc(newLocation);
        })
        .catch((error) => {
          setErrorMessage('Sorry, we were unable to retrieve your location.');
        });
    };

    if (coords !== null && !isCoordsLoading) {
      fetchLocation({ coords, setLoc, setErrorMessage });
      setIsLocLoading(false);
    }

    return () => source.cancel('Cancelling API call');
  }, [coords, isCoordsLoading]);

  // Update the "Popular in" drop-down to use the location information.
  // If this is going to be a drop down, we will need two different states
  // here. The first for the user's location, the second for the select's
  // value.
  const [popLocation, setPopLocation] = React.useState<string>('--');
  React.useEffect(() => {
    if (!!loc.city && !!loc.state_short && !isLocLoading) {
      setPopLocation(`${loc.city}, ${loc.state_short}`);
      setIsPopLocationLoading(false);
    }
  }, [loc, isLocLoading]);

  // Fetch data from getListings API call.
  const [localShops, setLocalShops] = React.useState<Array<IListingMemberShop>>(
    [] as Array<IListingMemberShop>,
  );
  
  const [localShopsSig, setLocalShopsSig] = React.useState<Array<IListingMemberShop>>(
    [] as Array<IListingMemberShop>,
  );

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const callGetListings = ({ loc, setLocalShops, setLocalShopsSig, setErrorMessage }: CallGetListingsProps) => {
      const shopsNotFoundError = 'Sorry, we could not find any popular shops in your area.';

      let searchLocation: ILocation = {} as ILocation;
      searchLocation.stateShortName = loc.state_short.toUpperCase();
      searchLocation.stateName = loc.state_long;
      searchLocation.cityName = loc.city.toUpperCase();

      let date = new Date();
      api
        .getListingsAndMemberShops(searchLocation, null, date)
        .then((results) => {
          if (isErrorMessage(results[0])) {
            setLocalShops([]);
			setLocalShopsSig([]);
            setErrorMessage(shopsNotFoundError);
          } else {
            setLocalShops(results.slice(0,3));
			setLocalShopsSig(results);
          }
        })
        .catch((error) => {
          setLocalShops([]);
		  setLocalShopsSig([]);
          setErrorMessage(shopsNotFoundError);
        });
    };

    if (!!loc.city && !!loc.state_long && !isPopLocationLoading) {
      callGetListings({ loc, setLocalShops, setLocalShopsSig, setErrorMessage });
      setIsLocalShopsLoading(false);
    }

    return () => source.cancel('Cancelling API call');
  }, [loc, isPopLocationLoading]);

  // Signature Arrangements.
  const [signatureArrangements, setSignatureArrangements] = React.useState<
    Array<ISignatureProduct>
  >([]);

  React.useEffect(() => {
    if (isLocalShopsLoading) return;
    const getCustomProducts = (shops: Array<IListingMemberShop>) => {
      const signatureArray: Array<ISignatureProduct> = [] as Array<ISignatureProduct>;

      shops.forEach((shop) => {
        const shopInfo = {
          shopCity: shop.shopCity,
          shopState: shop.shopState,
          shopCode: shop.shopCode,
          shopWebsite: shop.shopWebsite ?? null,
		  listing: shop,
		  loc : loc,
        };
        if (!!shop.shopCustomProducts) {
          const temp = shop.shopCustomProducts.split(';');
          temp.forEach((product) => {
            const newObj = {} as ICustomProduct;
            product.split(',').forEach((line) => {
              const keyVal = line.split(':');
              const key = keyVal[0]
                .trim()
                .slice(0, 1)
                .toLowerCase()
                .concat(keyVal[0].trim().slice(1));
              const val = keyVal[1].trim();
              newObj[key] = val;
            });
            signatureArray.push({ ...newObj, ...shopInfo });
          });
        }
      });
      return signatureArray;
    };

    if (!isLocalShopsLoading) setSignatureArrangements(getCustomProducts(localShopsSig));
  }, [localShopsSig, isLocalShopsLoading]);

  // Props objects
  const resultsProps = { loc, localShops, signatureArrangements };

  return hideSection ? null : (
    <ColWrapper>
      <SearchRow>
        <Heading className={classes.title}>Featured in</Heading>
        <Heading className={classes.location}>{popLocation}</Heading>
      </SearchRow>
      {!!errorMessage ? (
        <Typography className={classes.typographyPadding} variant="body2" gutterBottom>
          {errorMessage}
        </Typography>
      ) : null}
      {!!localShops ? <Results {...resultsProps} /> : <NoResults />}
    </ColWrapper>
  );
};

export default PopularSection;

const Heading = styled.h1`
  font-size: 30px;
  font-weight: 600;
`;

const SearchRow = styled.div`
  align-self: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 25px;

  @media only screen and (min-width: 768px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
