import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  vertical: {
    margin: '0 40px 0 20px',
  },
  horizontal: {
    margin: '20px 0',
    height: '1px',
    width: '300px',
    alignSelf: 'center',
    [theme.breakpoints.up('xs')]: {
      width: '350px',
    },
  },
}));

const VertAndHoriz: React.FC<{ idx: number }> = ({ idx }): React.ReactElement => {
  const classes = useStyles();
  return (
    <>
      {idx !== 2 ? (
        <Hidden smDown>
          <Divider className={classes.vertical} orientation="vertical" flexItem />
        </Hidden>
      ) : null}
      <Hidden mdUp>
        <Divider className={classes.horizontal} />
      </Hidden>
    </>
  );
};

export default VertAndHoriz;
