import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import apiError from '../../assets/api-error.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '50px 0',
  },
  image: {
    margin: '0 0 20px',
  },
  text: {
    font: 'normal normal 500 15px/23px Poppins',
    maxWidth: 308,
    textAlign: 'center',
    margin: '0 0 25px',
  },
  tryAgain: {
    textTransform: 'none',
    font: 'normal normal 500 15px/23px Poppins',
    width: '135px',
    height: '52px',
  },
}));

export interface PageNotFoundProps {}

const PageNotFound: React.FC<PageNotFoundProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img className={classes.image} src={apiError} alt="API Error Icon" />
      <Typography className={classes.text}>
        We’re sorry, the page you are looking could not be found.
      </Typography>
    </div>
  );
};

export default PageNotFound;
