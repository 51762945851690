import * as React from 'react';
import ReactGA, { EventArgs } from 'react-ga4';

export interface GALinkProp {
  eventLabel: string;
  to?: any;
  className?: string;
  style?: any;
}

const GALink: React.FC<GALinkProp> = ({
  eventLabel,
  to,
  className,
  style,
  children,
}): React.ReactElement => {
  return (
    <a
      className={className}
      style={style}
      href={to}
      target="_blank"
      rel="noopener noreferrer nofollow"
      onClick={() => {
        ReactGA.event({
          category: 'Outbound',
          action: 'Click',
          label: eventLabel,
        } as EventArgs);
      }}
    >
      {children}
    </a>
  );
};

export default GALink;
