import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import InputAdornment from '@material-ui/core/InputAdornment';
import placeIcon from '../../assets/Place-icon.png';
import { AutocompletePrediction } from '../../types/types';

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-adornedStart': {
      paddingLeft: '14px',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

interface PlaceAutoComplete {
  value: AutocompletePrediction | null;
  setValue: React.Dispatch<React.SetStateAction<AutocompletePrediction | null>>;
  error?: boolean;
  errorMessage?: string;
}

/**
 * Component pulled from Material-UI Example
 * https://material-ui.com/components/autocomplete/#google-maps-place
 * @param value
 * @param setValue
 * @param error
 * @param errorMessage
 * @constructor
 */
const PlaceAutoComplete: React.FC<PlaceAutoComplete> = ({
  value,
  setValue,
  error,
  errorMessage,
}): React.ReactElement => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<AutocompletePrediction[]>([]);

  const fetch = React.useMemo(
    () =>
      throttle(
        (request: { input: string }, callback: (results?: AutocompletePrediction[]) => void) => {
          (autocompleteService.current as any).getPlacePredictions(request, callback);
        },
        200,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: AutocompletePrediction[]) => {
      if (active) {
        let newOptions = [] as AutocompletePrediction[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      placeholder="Street Address, City, Zip Code"
      value={value}
      noOptionsText="No locations found with entered address"
      className={classes.autocomplete}
      onChange={(event: any, newValue: AutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          placeholder="Street Address, City, Zip Code"
          error={error ?? false}
          helperText={error ? errorMessage ?? '' : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={placeIcon}
                  alt="Location Marker Icon"
                  style={{ maxWidth: '16px', height: '24px', width: '24px' }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default PlaceAutoComplete;
