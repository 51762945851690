import * as React from 'react';
import styled from 'styled-components';
import { Toolbar } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LogoLink from '../../../links/LogoLink';
import NavigateBack from '../../../buttons/NavigateBack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  grow: {
    flexGrow: 1,
  },
  centerLogo: {
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  },
  navButton: {
    position: 'absolute',
    margin: 'auto 0',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const SelectedFloristContent = () => {
  const classes = useStyles();
  return (
    <HeaderRow>
      <div className={classes.root}>
        <div className={classes.navButton}>
          <NavigateBack />
        </div>
        <div className={classes.centerLogo}>
          <LogoLink />
        </div>
      </div>
    </HeaderRow>
  );
};

export default SelectedFloristContent;

// ********** HOME HEADER
export const HeaderRow = styled(Toolbar)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin: 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media only screen and (min-width: 960px) {
  }

  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
  }
  @media only screen and (min-width: 1920px) {
    max-width: 1400px;
  }
`;
