import * as React from 'react';
import styled from 'styled-components';
import LogoLink from '../../../links/LogoLink';

const HomeContent: React.FC<{}> = (): React.ReactElement => {
  return (
    <HomeHeaderRow>
      <LogoLink />
    </HomeHeaderRow>
  );
};

export default HomeContent;

export const HomeHeaderRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin: 0;
  width: 100%;
  padding: 0 0 0 25px;

  @media only screen and (min-width: 768px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media only screen and (min-width: 960px) {
  }

  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
  }
  @media only screen and (min-width: 1920px) {
    max-width: 1400px;
  }
`;
