import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Fab, Hidden } from '@material-ui/core';
import styled from 'styled-components';
import {
  IListingMemberShop,
  ILocation,
  IFacility,
  IErrorMessage,
  isErrorMessage,
  IFacilityTable,
  ShopCodeParam,
} from '../../types/types';
import api from '../../utilities/api';
import TitleBar from './TitleBar';
import DetailsTabs from './tabs/DetailsTabs';
import { ContentWrapper, MobileWrapper } from '../../components/layout/wrappers';
import { parseISO } from 'date-fns';
import { streetAddress, titleCaser } from '../../utilities/javascripts';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

// Images
import placeholder from '../../assets/mock-images/storefront_placeholder.png';
import { baseImageUrl } from '../index';
import LocationDetailsRow from './LocationDetailsRow';
import { internalNavButtonGA } from '../../utilities/googleAnalytics';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignSelf: 'center',
    margin: '0 0 20px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '100px',
      paddingRight: '100px',
      margin: '0 0 60px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1400px',
    },
  },
  columnWrapper: {
    display: 'flex',
    flexFlow: ' column nowrap',
    justifyContent: ' flex-start',
    alignItems: ' center',
    width: '100%',
    height: '100%',
  },
  detailsImage: {
    objectFit: 'cover',
    width: '100%',
    height: '250px',
    margin: '0 0 25px',
    borderRadius: '0px',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 0 20px 0',
      borderRadius: '10px',
      height: '350px',
    },
  },
}));

/**
 * Florist Details Properties
 */
export interface FloristDetailsProps {
  setHeaderContent: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Florist Details Page
 * @param setHeaderContent
 * @constructor
 */
const FloristDetailsPage: React.FC<FloristDetailsProps> = ({
  setHeaderContent,
}): React.ReactElement => {
  const classes = useStyles();
  const { shopCode } = useParams<ShopCodeParam>();
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    setHeaderContent('selected');
  }, [setHeaderContent]);

  // Use Data sent from listing page if available
  const state = location.state ?? {};
  const defaultData = state as IListingMemberShop;
  const [details, setDetails] = React.useState<IListingMemberShop>(defaultData);
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    // Bail out if there is already data on state
    if (location.state) return;

    // Get Listing details from URL parameters
    const searchParams = new URLSearchParams(location.search);
    const city = searchParams.get('city');
    const state = searchParams.get('state');
    const stateCode = searchParams.get('sc');
    const facility = searchParams.get('facility');
    const dateParam = searchParams.get('date');

    const facilityId = Number(facility) || null;
    const date = dateParam ? parseISO(dateParam) : new Date();
    const listLocation = {
      cityName: city ?? '',
      stateName: state ?? '',
      stateShortName: stateCode ?? '',
    };

    // Get Default Florist
    const fetchDefault = (date: Date) => {
      api
        .getWorldFlowers(date)
        .then((data) => {
          if (data.length > 0) {
            setDetails(data[0]);
          }
        })
        .catch((e) => {
          // TODO - Handle / Log error.
          // Go to listings page
          history.push({
            pathname: '/our-florists',
            search: searchParams.toString(),
          });
        });
    };

    // Get Latest Data
    const fetchListing = (
      searchLocation: ILocation,
      facilityId: number | null,
      date: Date,
      shopCode?: string,
    ) => {
      api
        .getListingsAndMemberShops(searchLocation, facilityId, date, shopCode)
        .then((data) => {
          if (data.length > 0) {
            setDetails(data[0]);
          }
        })
        .catch((e) => {
          // TODO - Handle / Log error.
          // Go to listings page
          history.push({
            pathname: '/our-florists',
            search: searchParams.toString(),
          });
        });
    };

    shopCode === 'A9999999'
      ? fetchDefault(date)
      : fetchListing(listLocation, facilityId, date, shopCode);

    return () => source.cancel('Cancelling API call');
  }, [location, shopCode, history]);

  const [facilities, setFacilities] = React.useState<IFacility[]>([] as IFacility[]);
  const [facilitiesError, setFacilitiesError] = React.useState<IErrorMessage[]>(
    [] as IErrorMessage[],
  );

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchFacilities = () => {
      api
        .getFacilitiesByIds(details.facilitiesServiced)
        .then((res) => {
          if (isErrorMessage(res.data[0])) {
            setFacilitiesError(res.data as IErrorMessage[]);
            setFacilities([] as IFacility[]);
          } else {
            setFacilitiesError([] as IErrorMessage[]);
            setFacilities(res.data as IFacility[]);
          }
        })
        .catch((err) => {
          // TODO - Handle / Log error.
          if (process.env.NODE_ENV === 'development') console.log(err);
          else return;
        });
    };

    fetchFacilities();

    return () => source.cancel('Cancelling API call');
  }, [details.facilitiesServiced]);

  // Collect data for the table on the details page.
  const [tableData, setTableData] = React.useState<Array<IFacilityTable>>(
    [] as Array<IFacilityTable>,
  );
  React.useEffect(() => {
    if (!facilities.length) return;

    const makeData = (facilities: Array<IFacility>): void => {
      let temp: Array<IFacilityTable> = [] as Array<IFacilityTable>;
      facilities.map((f) => {
        let obj = {
          facilityName: f.facilityName,
          address: `${streetAddress(f.facilityAddress1, f.facilityAddress2)}
${titleCaser(f.facilityCity)}, ${titleCaser(f.facilityState)} ${f.facilityZip}`,
          facilityType: f.facilityType,
          facilityID: f.facilityID,
        };
        return temp.push(obj);
      });
      setTableData([...temp]);
    };

    makeData(facilities);
  }, [facilities]);

  const handleBackButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    history.goBack();
    internalNavButtonGA(e.currentTarget.id);
  };

  return (
    <RelativeWrapper>
      <Helmet>
        <title>{details.shopName + '- Consumer Directory Online'}</title>
        <meta
          name="description"
          content={`See available delivery info for ${details.shopName} in ${details.shopCity},${details.shopState}`}
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/our-florists/${details.shopCode}`}
        />
        <meta property="og:image" content={baseImageUrl.concat(details.consumerImage)} />
        <link rel="canonical" href={`${window.location.origin}/our-florists/${details.shopCode}`} />
      </Helmet>
      <DetailsCol itemScope itemType="http://schema.org/LocalBusiness">
        <Hidden xsDown>
          <BackButton
            id="details-page-nav-button"
            aria-label="back"
            onClick={handleBackButtonClick}
          >
            <NavigateBeforeIcon color="inherit" fontSize="large" />
          </BackButton>
          <TitleBar {...details} />

          {!imageLoaded ? (
            <img className={classes.detailsImage} src={placeholder} alt="Placeholder" />
          ) : null}
          {!!details.consumerImage ? (
            <img
              itemProp="image"
              className={classes.detailsImage}
              src={baseImageUrl.concat(details.consumerImage)}
              alt={details.shopName}
              onLoad={() => setImageLoaded(true)}
              style={!imageLoaded ? { display: 'none' } : {}}
            />
          ) : null}

          <LocationDetailsRow {...details} />
          <DetailsTabs
            listing={details}
            facilities={facilities}
            facilitiesError={facilitiesError}
            tableData={tableData}
          />
        </Hidden>
        <Hidden smUp>
          {!imageLoaded ? (
            <img className={classes.detailsImage} src={placeholder} alt="Placeholder" />
          ) : null}
          {!!details.consumerImage ? (
            <img
              itemProp="image"
              className={classes.detailsImage}
              src={baseImageUrl.concat(details.consumerImage)}
              alt={details.shopName}
              onLoad={() => setImageLoaded(true)}
              style={!imageLoaded ? { display: 'none' } : {}}
            />
          ) : null}
          <MobileWrapper>
            <TitleBar {...details} />
            <LocationDetailsRow {...details} />
            <DetailsTabs
              listing={details}
              facilities={facilities}
              facilitiesError={facilitiesError}
              tableData={tableData}
            />
          </MobileWrapper>
        </Hidden>
      </DetailsCol>
    </RelativeWrapper>
  );
};

export default FloristDetailsPage;

const RelativeWrapper = styled(ContentWrapper)`
  position: relative;
`;

const DetailsCol = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  margin: 0;

  @media only screen and (min-width: 768px) {
    margin: 36px 0 0;
  }
`;

const BackButton = styled(({ ...props }) => <Fab {...props}>{props.children}</Fab>)`
  && {
    display: none;
    position: absolute;
    top: 43px;
    left: 25px;
    height: 50px;
    width: 50px;
    box-shadow: none;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    opacity: 1;

    @media only screen and (min-width: 768px) {
      display: inline-flex;
    }
  }
`;
