import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import footerAd from '../../../assets/ad-images/footer-ad.jpg';

interface IAdvertiseWithUs {}

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  image: {
    borderRadius: '4px',
    objectFit: 'scale-down',
    maxWidth: '100%',
    maxHeight: '90px',
  },
}));

const AdvertiseWithUs: React.FC<IAdvertiseWithUs> = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <a href="https://www.bloomnet.net/solutions/advertise-with-us" target="_blank"><img src={footerAd} alt="Advertise With Us Link" className={classes.image} /></a>
    </div>
  );
};

export default AdvertiseWithUs;
