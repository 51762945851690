import styled from 'styled-components';

export const ColWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
  margin: 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media only screen and (min-width: 960px) {
  }

  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
  }
  @media only screen and (min-width: 1920px) {
    max-width: 1400px;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 25px 25px;
`;
