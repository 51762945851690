import * as React from 'react';
import { IAdvertisement } from '../../types/types';
import { Grid } from '@material-ui/core';
import { getValidUrl } from '../../utilities/javascripts';
import { makeStyles } from '@material-ui/core/styles';
import GALink from '../../components/links/GALink';

const useStyles = makeStyles((theme) => ({
  banners: {
    objectFit: 'scale-down',
    maxWidth: '100%',
    maxHeight: '182px',
  },
}));

export interface FloristAdSpaceProps {
  advertisements: Array<IAdvertisement> | null;
}

const FloristAdSpace: React.FC<FloristAdSpaceProps> = ({ advertisements }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} direction="column">
      {advertisements &&
        advertisements.map((ad) => {
          return (
            <Grid item xs={12} key={ad.fileName}>
              <GALink eventLabel={`AdSpace Click: ${ad.website}`} to={getValidUrl(ad.website)}>
                <img
                  className={classes.banners}
                  src={ad.fileName}
                  alt={!!ad.website ? ad.website : 'stock floral image'}
                />
              </GALink>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default FloristAdSpace;
