import axios, { AxiosResponse } from 'axios';
import convert from 'xml-js';
import * as T from '../types/types';
import {
  IErrorMessage,
  IListing,
  IMemberResponse,
  IMemberShop,
  isErrorMessage,
  isMemberResponse,
  mergeListingAndMembers,
  soloListings,
} from '../types/types';
import { format } from 'date-fns';
const apiKey =  process.env.REACT_APP_API_KEY;
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

/**
 * Search Listings by City and State
 * @param city
 * @param state
 * @param shopCode - Optional parameter
 */
export const getListings = (
  city: string,
  state: string,
  shopCode?: string,
): Promise<AxiosResponse<T.IListing[] | T.IErrorMessage[]>> => {
  return axios.get<T.IListing[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchListingsByCityAndState`,
    {
      params: {
        apiKey,
        city,
        state,
        ...(shopCode ? { shopCode } : {}),
      },
      paramsSerializer,
    },
  );
};

/**
 * Search Facility Listings by City and State
 * @param city
 * @param state
 * @param facilityID
 * @param shopCode - Optional parameter
 */
const getFacilityListings = (
  city: string,
  state: string,
  facilityID: number,
  shopCode?: string,
): Promise<AxiosResponse<T.IListing[] | T.IErrorMessage[]>> => {
  return axios.get<T.IListing[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchFacilityListingsByCityAndState`,
    {
      params: {
        apiKey,
        city,
        state,
        facilityID,
        ...(shopCode ? { shopCode } : {}),
      },
      paramsSerializer,
    },
  );
};

/**
 * Search Facilities by City, State, and Type
 * @param city
 * @param state
 * @param facilityTypeID
 */
const getFacilitiesByCityAndState = (
  city: string,
  state: string,
  facilityTypeID: number,
): Promise<AxiosResponse<T.IFacility[] | T.IErrorMessage[]>> => {
  return axios.get<T.IFacility[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchFacilitiesByCityAndState`,
    {
      params: {
        apiKey,
        city,
        state,
        facilityTypeID,
      },
      paramsSerializer,
    },
  );
};

/**
 * Search Facilities by IDs
 * @param facilityIDs
 */
const getFacilitiesByIds = (
  facilityIDs: string,
): Promise<AxiosResponse<T.IFacility[] | T.IErrorMessage[]>> => {
  return axios.get<T.IFacility[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchFacilitiesByFacilityIDs`,
    {
      params: {
        apiKey,
        facilityIDs,
      },
      paramsSerializer,
    },
  );
};

/**
 * Search Countries
 */
const getCountries = (): Promise<AxiosResponse<T.ICountry[] | T.IErrorMessage[]>> => {
  return axios.get<T.ICountry[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchCountries`,
    {
      params: {
        apiKey,
      },
      paramsSerializer,
    },
  );
};

/**
 * Search States by Country
 * @param country
 */
const getStates = (country: string): Promise<AxiosResponse<T.IState[] | T.IErrorMessage[]>> => {
  return axios.get<T.IState[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchStatesByCountry`,
    {
      params: {
        apiKey,
        country,
      },
      paramsSerializer,
    },
  );
};

/**
 * Search Cities by State
 * @param state
 */
const getCities = (state: string): Promise<AxiosResponse<T.ICity[] | T.IErrorMessage[]>> => {
  return axios.get<T.ICity[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchCitiesByState`,
    {
      params: {
        apiKey,
        state,
      },
      paramsSerializer,
    },
  );
};

/**
 * Search Cities and State by Zip
 * @param zip
 */
const getCityAndState = (
  zip: string,
): Promise<AxiosResponse<T.ILocation[] | T.IErrorMessage[]>> => {
  return axios.get<T.ILocation[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchCityAndStateByZip`,
    {
      params: {
        apiKey,
        zip,
      },
      paramsSerializer,
    },
  );
};

/**
 * Search Ships by ShopCode
 * @param shopCode
 */
const getShop = (shopCode: string): Promise<AxiosResponse<T.IListing[] | T.IErrorMessage[]>> => {
  return axios.get<T.IListing[] | T.IErrorMessage[]>(
    `https://directory.bloomnet.net/Directory/services/v1/SearchShopByShopCode`,
    {
      params: {
        apiKey,
        shopCode,
      },
      paramsSerializer,
    },
  );
};

/**
 * Get BloomLink Membership Hours By ShopCode
 * @param shopCode
 */
const getMembershipHours = (shopCode: string): Promise<AxiosResponse<T.IMemberResponse>> => {
  return axios
    .get(
      `https://directory.bloomnet.net/Directory/services/v1/GetBloomLinkMembershipHoursByShopCode`,
      {
        params: {
          apiKey,
          shopCode,
        },
        paramsSerializer,
      },
    )
    .then(convertXMLToJS)
    .then(removeEmptyObjectsFromResponse)
    .then(convertToMemberInterface);
};

/**
 * Gets coordinates using Google's geolocation api.
 * returns a data object
 * const { lat, lng } = results.data.location
 */
const getLatLng = () => {
  return axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${googleApiKey}`, {
    considerIp: 'true',
  });
};

/**
 *@param latlng the latitude and longitude returned from the geolocation call, combined with no spaces (lat,lng)
 */
const getUsersCityStateZip = (latlng: string): Promise<AxiosResponse> => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${googleApiKey}`,
  );
};

/**
 * Lookup location to get Lat Long
 *@param location - Location of Listing
 */
const lookupLocation = (location: string): Promise<AxiosResponse> => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${googleApiKey}`,
  );
};

/**
 * Get data from legacy api for business hours and open / closed status.
 * @param date - MM/dd/yyyy
 * @param city - City name and must be uppercase - Ex. NEW YORK CITY
 * @param state - SS - State Short Name and must be uppercase - Ex. NY
 * @param zip - #####
 */
export const getMemberDirectory = (
  date: string,
  city?: string,
  state?: string,
  zip?: string,
): Promise<AxiosResponse<T.IMemberResponse>> => {
  return axios
    .get(`https://directory.bloomnet.net/Directory/services/v1/GetBloomLinkMembershipHours`, {
      params: {
        apiKey,
        date,
        city,
        state,
        zip,
      },
      paramsSerializer,
    })
    .then(convertXMLToJS)
    .then(removeEmptyObjectsFromResponse)
    .then(convertToMemberInterface);
};

const getWorldFlowers = (date: Date): Promise<Array<T.IListingMemberShop>> => {
  const location = {
    cityName: 'World Flowers',
    stateName: 'International',
    stateShortName: 'INT',
  };
  return getListingsAndMemberShops(location, null, date, 'A9999999');
};

/**
 * Gets Member Shops and Listings and Merges together
 * @param searchLocation
 * @param facilityId
 * @param date
 * @param shopCode
 */
const getListingsAndMemberShops = (
  searchLocation: T.ILocation,
  facilityId: number | null,
  date: Date,
  shopCode?: string,
): Promise<Array<T.IListingMemberShop>> => {
  let requestListings: Promise<AxiosResponse<T.IListing[] | T.IErrorMessage[]>>;
  let requestMember: Promise<AxiosResponse<T.IMemberResponse>>;

  if (shopCode && searchLocation.cityName === '' && searchLocation.stateName === '') {
    // No Location so use basic API mainly used for google crawlers
    requestListings = getShop(shopCode);
    requestMember = getMembershipHours(shopCode);
  } else {
    // get Shop listing
    requestListings = facilityId
      ? getFacilityListings(searchLocation.cityName, searchLocation.stateName, facilityId, shopCode)
      : getListings(searchLocation.cityName, searchLocation.stateName, shopCode);

    requestMember = getMemberDirectory(
      format(date, 'MM/dd/yyyy'),
      searchLocation.cityName,
      searchLocation.stateShortName,
    );
  }

  const handleResponses = (
    res1: AxiosResponse<IListing[] | IErrorMessage[]> | AxiosResponse<IMemberResponse>,
    res2: AxiosResponse<IListing[] | IErrorMessage[]> | AxiosResponse<IMemberResponse>,
  ) => {
    if (res1.status !== 200) {
      throw new Error(res1.statusText);
    }
    if (res2.status !== 200) {
      throw new Error(res2.statusText);
    }

    const validationResult1 = validateData(res1.data);
    if (!validationResult1.valid) {
      throw new Error(validationResult1.error.errorMessage);
    }
    const validationResult2 = validateData(res2.data);
    if (!validationResult2.valid) {
      throw new Error(validationResult2.error.errorMessage);
    }

    return mergeListingAndMembers(
      validationResult1.listings || validationResult2.listings,
      validationResult1.shops || validationResult2.shops,
    );
  };

  const handleResponse = (
    res1: AxiosResponse<IListing[] | IErrorMessage[]> | AxiosResponse<IMemberResponse>,
  ) => {
    if (res1.status !== 200) {
      throw new Error(res1.statusText);
    }

    const validationResult1 = validateData(res1.data);
    if (!validationResult1.valid) {
      throw new Error(validationResult1.error.errorMessage);
    }

    return soloListings(validationResult1.listings);
  };

  interface IValidationResult {
    valid: boolean;
    error: IErrorMessage;
    listings: Array<IListing>;
    shops: Array<IMemberShop>;
  }
  const validateData = (
    data: IListing[] | IErrorMessage[] | IMemberResponse,
  ): IValidationResult => {
    const results = { valid: false } as IValidationResult;

    if (isMemberResponse(data)) {
      const shopResp = data.memberDirectoryInterface.searchShopResponse;
      if (shopResp.errors && shopResp.errors.error) {
        // Errors
        const errors = shopResp.errors.error;
        if (Array.isArray(errors) && errors.length > 0) {
          results.error = errors[0] as IErrorMessage;
        } else if (isErrorMessage(errors)) {
          results.error = errors as IErrorMessage;
        }
        // Data not required
        results.valid = true;
        results.shops = [];
      } else if (shopResp.shops && shopResp.shops.shop) {
        if (Array.isArray(shopResp.shops.shop)) {
          results.valid = true;
          results.shops = shopResp.shops.shop;
        } else {
          results.valid = true;
          results.shops = [shopResp.shops.shop];
        }
      }
    } else if (Array.isArray(data) && data.length > 0) {
      if (isErrorMessage(data[0])) {
        // Error
        results.error = data[0] as IErrorMessage;
      } else {
        results.valid = true;
        results.listings = data as Array<IListing>;
      }
    }
    return results;
  };

  return Promise.all([requestListings, requestMember])
    .then(([res1, res2]) => {
      return handleResponses(res1, res2);
    })
    .catch(() => {
      return Promise.all([requestListings]).then(([res1]) => {
        return handleResponse(res1);
      });
    });
};

/**
 * Get landing page advertisements.
 */
const getLandingPageAds = () => {
  return axios.get(`https://directory.bloomnet.net/Directory/services/v1/SearchLandingAds`, {
    params: {
      apiKey,
    },
    paramsSerializer,
  });
};

/**
 * Get results page advertisements.
 */
export const getResultsPageAds = (city: string, state: string) => {
  return axios.get(`https://directory.bloomnet.net/Directory/services/v1/SearchResultsAds`, {
    params: {
      apiKey,
      city,
      state,
    },
    paramsSerializer,
  });
};

// -----------------------------------------
// ---------------- HELPERS ----------------
// -----------------------------------------

/**
 * Encodes a text string as a valid component of a URI
 * Override axios encode to not convert spaces from %20 to +
 * @param val
 */
function encode(val: string) {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']');
}

/**
 * Custom Params Serializer since axios handles spaces as + instead of %20
 * Modified version of https://github.com/axios/axios/blob/4b3947aa59aaa3c0a6187ef20d1b9dddb9bbf066/lib/helpers/buildURL.js
 * @param params
 */
export const paramsSerializer = (params: any) => {
  let parts = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      let val = params[key];
      if (val === null || typeof val === 'undefined') {
        continue;
      }
      parts.push(encode(key) + '=' + encode(val));
    }
  }
  return parts.join('&');
};

/**
 * Create XML API data parameters
 * Currently unused, but saving just in case.
 * @param availabilityDate
 * @param city
 * @param state
 * @param zipCode
 */
// const createMemberDirectoryInterface = (
//   availabilityDate: string,
//   city?: string,
//   state?: string,
//   zipCode?: number,
// ): string => {
//   return (
//     `<memberDirectoryInterface>` +
//     `<searchShopRequest>` +
//     `<security><username></username><password></password><shopCode>Z1010000</shopCode></security>` +
//     `<memberDirectorySearchOptions>` +
//     `<searchByAvailabilityDate>` +
//     `<availabilityDate>${availabilityDate}</availabilityDate>` +
//     `<zipCode>${zipCode ? zipCode : ''}</zipCode>` +
//     `<city>${city ? city.toUpperCase() : ''}</city>` +
//     `<state>${state ? state.toUpperCase() : ''}</state>` +
//     `</searchByAvailabilityDate>` +
//     `</memberDirectorySearchOptions>` +
//     `</searchShopRequest>` +
//     `</memberDirectoryInterface>`
//   );
// };

/**
 * Data is in XML format convert it to a Javascript Object
 * Converter - https://github.com/nashwaan/xml-js
 * @param response
 */
const convertXMLToJS = (response: AxiosResponse): AxiosResponse => {
  if (response.data) {
    try{
		response.data = convert.xml2js(response.data, {
		  ignoreComment: true,
		  ignoreDeclaration: true,
		  compact: true,
		  textFn: removeJsonTextAttribute,
		});
	} catch(e){//console.trace(e); 
	}
  }
  return response;
};

/**
 * Remove empty objects
 * The empty objects are empty XML tags that the parsed didnt know what to convert too
 * @param response
 */
const removeEmptyObjectsFromResponse = (response: AxiosResponse): AxiosResponse => {
  if (response.data) {
    clearEmptyObjects(response.data);
  }
  return response;
};

/**
 * Convert Object to a Typescript MemberResponse Interface
 * @param response
 */
const convertToMemberInterface = (response: AxiosResponse): AxiosResponse<T.IMemberResponse> => {
  if (response.data) {
    response.data = response.data as T.IMemberResponse;
  }
  return response;
};

/**
 * Clear any empty objects from parent
 * @param o
 */
const clearEmptyObjects = (o: any): any => {
  for (const k in o) {
    if (!o.hasOwnProperty(k) || !o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }

    // The property is an object
    clearEmptyObjects(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
};

/**
 * Converts value to a native type
 * @param value
 */
const nativeType = (value: any) => {
  const nValue = Number(value);
  if (!isNaN(nValue)) {
    return nValue;
  }
  const bValue = value.toLowerCase();
  if (bValue === 'true') {
    return true;
  } else if (bValue === 'false') {
    return false;
  }
  return value;
};

/**
 * removes json text attribute
 * Ex: converts { property: { _text: "Value"; } } => { property: "Value" }
 * @param value
 * @param parentElement
 */
const removeJsonTextAttribute = (value: any, parentElement: any) => {
  try {
    const parentOfParent = parentElement._parent;
    const pOpKeys = Object.keys(parentOfParent);
    const keyNo = pOpKeys.length;
    const keyName = pOpKeys[keyNo - 1];
    const arrOfKey = parentOfParent[keyName];
    const arrOfKeyLen = arrOfKey.length;
    if (arrOfKeyLen > 0) {
      const arr = arrOfKey;
      const arrIndex = arrOfKey.length - 1;
      arr[arrIndex] = nativeType(value);
    } else {
      parentOfParent[keyName] = nativeType(value);
    }
  } catch (e) {}
};

export default {
  getListings,
  getFacilityListings,
  getFacilitiesByCityAndState,
  getFacilitiesByIds,
  getCountries,
  getStates,
  getCities,
  getCityAndState,
  getUsersCityStateZip,
  getMemberDirectory,
  getListingsAndMemberShops,
  getWorldFlowers,
  lookupLocation,
  getLatLng,
  getLandingPageAds,
  getResultsPageAds,
};
