import React from 'react';
import { isIOS } from 'react-device-detect';
import { Type15 } from '../font';
import { streetAddress, titleCaser } from '../../utilities/javascripts';

export interface PhoneLinkProp {
  id?: string; // Used for Structured Data Markup
  phone: string;
}
export const PhoneLink: React.FC<PhoneLinkProp> = ({ id, phone }): React.ReactElement => {
  return (
    <Type15>
      <a href={`tel:${phone}`} style={{ textDecoration: 'none' }}>
        <span id={id} itemProp="telephone">
          {phone}
        </span>
      </a>
    </Type15>
  );
};

export interface AddressLinkProp {
  id?: string; // Used for Structured Data Markup
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}
export const AddressLink: React.FC<AddressLinkProp> = ({
  id,
  address1,
  address2,
  city,
  state,
  zip,
}): React.ReactElement => {
  const url = isIOS
    ? `http://maps.apple.com?q=${address1} ${address2} ${city} ${state} ${zip}`
    : `http://maps.google.com?q=${address1} ${address2} ${city} ${state} ${zip}`;
  return (
    <a
      id={id}
      itemProp="address"
      itemScope
      itemType="http://schema.org/PostalAddress"
      target="_blank"
      rel="noopener noreferrer nofollow"
      href={url}
      style={{ textDecoration: 'none' }}
    >
      <Type15 itemProp="streetAddress">{streetAddress(address1, address2)}</Type15>
      <Type15>
        <span itemProp="addressLocality">{city ? titleCaser(city) : ''}</span>,{' '}
        <span itemProp="addressRegion">{state}</span> <span itemProp="postalCode">{zip}</span>
      </Type15>
    </a>
  );
};
