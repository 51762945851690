import * as React from 'react';
import { Hidden, Toolbar } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LogoLink from '../../../links/LogoLink';
import NavigateBack from '../../../buttons/NavigateBack';
import FindFlorist, { SearchType } from '../../../forms/FindFlorist';
import { HeaderRow } from './SelectedFloristContent';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: '0',
    [theme.breakpoints.up('md')]: {
      padding: '0 0 0 25px',
    },
  },
  grow: {
    flexGrow: 1,
  },
  centerLogo: {
    margin: '0 auto',
  },
  navButton: {
    position: 'absolute',
    margin: 'auto 0',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  smallRow: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
}));

/**
 * Header for Our Florists page
 * @constructor
 */
export const FloristsHeaderContent = () => {
  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Toolbar className={classes.toolbar}>
          <LogoLink />
          <div className={classes.grow} />
          <FindFlorist type={SearchType.Compact} />
          <div className={classes.grow} />
        </Toolbar>
      </Hidden>
      <Hidden mdUp>
        <HeaderRow>
          <div className={classes.smallRow}>
            <div className={classes.navButton}>
              <NavigateBack />
            </div>
            <div className={classes.centerLogo}>
              <LogoLink />
            </div>
          </div>
        </HeaderRow>
      </Hidden>
    </>
  );
};

export default FloristsHeaderContent;
