import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import placeIcon from '../../../assets/Place-icon.png';
import phone from '../../../assets/phone.png';
import document from '../../../assets/document.png';
import { ShoppingBagIcon } from '../../../components';
import { IListingMemberShop } from '../../../types/types';
import { PhoneLink, AddressLink } from '../../../components';
import { getValidUrl } from '../../../utilities/javascripts';
import { internalNavButtonGA } from '../../../utilities/googleAnalytics';
import { Paper, Button } from '@material-ui/core';
import { PurpleFlower2 } from '../../../assets/svg-files/PurpleFlower2';
import clsx from 'clsx';
import GALink from '../../../components/links/GALink';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '10px',
    width: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '12px',
  },
  shopNameRow: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '12px',
  },
  shopName: {
    maxWidth: 'calc(100% - 62px)',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0',
  },
  openIndicator: {
    maxWidth: '62px',
    margin: '0 10px',
  },
  openText: {
    color: '#000000',
    padding: '3px 10px',
    font: '500 12px/18px Poppins',
  },
  sponsoredRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '12px',
  },
  sponsoredWrapper: {
    backgroundColor: theme.palette.secondary.main,
    width: '90px',
    height: '25px',
    borderRadius: '18px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 20px 0 0',
  },
  sponsoredTextWrap: {
    width: '85px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'left',
    alignItems: 'center',
  },
  sponsoredText: {
    font: '500 12px/18px Poppins',
    color: '#ffffff',
  },
  noLinkStyling: {
    textDecoration: 'none',
    width: '100%',
  },
  littleFlower: {
    width: '14.14px',
    height: '16.5px',
    color: '#ffffff',
  },
  button: {
    font: '500 15px/23px Poppins',
    textTransform: 'none',
    borderRadius: '5px',
  },
  buttonWrapper: {
    width: '100%',
    marginBottom: '12px',
  },
  locationIcon: {
    width: '14px',
    height: '19.88px',
    margin: '0 20px 0 0',
  },
  phoneIcon: {
    margin: '0 10px 0 0',
  },
  fontStyle: {
    font: '500 12px/18px Poppins',
  },
}));

export interface MarkerCardProps {
  listing: IListingMemberShop;
}

const MarkerCard: React.FC<MarkerCardProps> = ({ listing }) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    shopCode,
    shopName,
    shopWebsite,
    shopCity,
    shopState,
    shopAddress1,
    shopAddress2,
    shopZip,
    shopPhone,
    isOpen,
    listingAdSize,
    listingBid,
	listingCitySponsorAd,
  } = listing;

  const handleViewOnMapBtn = (e: any) => {
    internalNavButtonGA(e.currentTarget.id);
  };

  const params = new URLSearchParams(location.search);

  return (
    <div className={classes.card}>
      <div className={classes.content}>
        {/* Sponsored Row */}
        {!!listingCitySponsorAd ? (
          <div className={classes.sponsoredRow}>
            <Paper elevation={0} className={classes.sponsoredWrapper}>
              <div className={classes.sponsoredTextWrap}><Typography>&nbsp;</Typography>
                <PurpleFlower2 className={classes.littleFlower} />
                <Typography className={classes.sponsoredText}>&nbsp;&nbsp;Featured</Typography>
              </div>
            </Paper>
          </div>
        ) : null}

        {/* Shop Name */}
        <div className={classes.shopNameRow}>
          <Typography className={classes.shopName} variant="h6">
            {shopName}
          </Typography>
          <div className={classes.openIndicator}>
            <OpenIndicator className="open-indicator" isOpen={isOpen}>
              <Typography className={classes.openText}>{isOpen ? 'Open' : 'Closed'}</Typography>
            </OpenIndicator>
          </div>
        </div>

        {/* Address Row */}
        <div className={clsx(classes.row, classes.fontStyle)}>
          <img className={classes.locationIcon} src={placeIcon} alt="Location Marker Icon" />
          <div>
            <AddressLink
              address1={shopAddress1}
              address2={shopAddress2}
              city={shopCity}
              state={shopState}
              zip={shopZip}
            />
          </div>
        </div>

        {/* Phone Row */}
        <div className={classes.row}>
          <img className={classes.phoneIcon} src={phone} alt="Phone Icon" />

          <PhoneLink phone={shopPhone} />
        </div>

        {/* Button Rows */}
        <div className={classes.row}>
          <Button
            id="results-view-details"
            component={RouterLink}
            className={classes.button}
            fullWidth
            to={{
              pathname: `/our-florists/${shopCode}`,
              search: params.toString(),
              state: listing,
            }}
            onClick={handleViewOnMapBtn}
            color="default"
            variant="contained"
            disableElevation
            startIcon={<img src={document} alt="Document icon" />}
          >
            View Details
          </Button>
        </div>

        {!!shopWebsite ? (
          <div className={classes.row}>
            <GALink
              className={classes.noLinkStyling}
              eventLabel={`Florists Results Website Click; ShopCode: ${shopCode}, Website: ${shopWebsite}`}
              to={getValidUrl(shopWebsite)}
            >
              <Button
                className={classes.button}
                fullWidth
                color="primary"
                variant="contained"
                disableElevation
                startIcon={<ShoppingBagIcon color="#ffffff" />}
              >
                Shop Now
              </Button>
            </GALink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MarkerCard;

const BaseWrapper: React.FC<BaseWrapperProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
);

type BaseWrapperProps = {
  className: string;
  children: React.ReactNode;
  isOpen: boolean;
};

const OpenIndicator = styled(BaseWrapper)`
  background: ${(props) =>
    props.isOpen
      ? `rgba(125, 200, 131, .25) 0% 0% no-repeat padding-box`
      : `rgba(233, 2, 2, .12) 0% 0% no-repeat padding-box`};
  border-radius: 19px;
`;
