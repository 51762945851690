import ReactGA from 'react-ga4';

/**
 * The Google Analytics tracking id; replace with any other tracking id that you want to use.
 * Bloomnet trackingId: UA-28661530-1
 */
export const gaID: string = 'G-D69VE5EB2T';

/**
 *    ***** CATEGORIES *****
 *    Outbound:         outbound link clicks (shop now, florist website, social media links).
 *    User Navigation:  internal navigation clicks (view details, view on map, page scrolls,
 *                      breadcrumbs, back nav, logolinks).
 *    User Search:      user entered search terms for florists, returns a JSON string that can be parsed into an object.
 *    No Results:       special event reporting when the user's search terms yield no results
 */

/**
 * Internal Nav Event tracking.
 * @param buttonId the ID property of the button component
 */
export const internalNavButtonGA = (buttonId: string) => {
  try {
    ReactGA.event({
      category: 'User Navigation',
      action: `Internal Navigation`,
      label: buttonId,
    });
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.log(err);
    else return;
  }
};

/**
 * Tracks use of search button use.
 * @param searchParams JSON.stringify'd object of the search params. JSON.parse it for the object.
 */
export const searchButtonGA = (buttonId: string, searchParams: string) => {
  try {
    ReactGA.event({
      category: 'User Search',
      action: `Search: ${buttonId}`,
      label: searchParams,
    });
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.log(err);
    else return;
  }
};

/**
 * Tracks use of outbound links that do not use the <OutboundLink> component.
 * @param buttonId the ID of the outbound link button.
 */
export const customOutboundGA = (buttonId: string) => {
  try {
    ReactGA.event({
      category: 'Outbound',
      action: 'Click',
      label: buttonId,
    });
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.log(err);
    else return;
  }
};

/**
 * Custom event tracking for a User Search that yields no results / only the default World Flowers results.
 * @param searchParams JSON.stringify'd object of the search params. JSON.parse it for the object.
 */
export const noSearchResultsGA = (searchParams: string) => {
  const params = !!searchParams ? searchParams : 'Params unavailable.';
  try {
    ReactGA.event({
      category: 'No Results',
      action: 'User Search Yields No Results',
      label: params,
    });
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.log(err);
    else return;
  }
};
