import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IListingMemberShop } from '../../types/types';
import { internalNavButtonGA } from '../../utilities/googleAnalytics';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  crumbWrapper: {
    display: 'none',
    marginTop: '10px',
    fontSize: '15px',
    lineHeight: '23px',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  crumb: {
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: 15,
    lineHeight: '23px',
    fontWeight: 500,
    padding: '0',
  },
  crumbLink: {
    color: '#0d0d0d',
  },
}));

const TitleBar: React.FC<IListingMemberShop> = (listing): React.ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const handleCrumbClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    internalNavButtonGA(e.currentTarget.id);
  };

  const Crumbs: React.FC = (): React.ReactElement => (
    <Breadcrumbs className={classes.crumbWrapper}>
      <RouterLink
        id="details-page-breadcrumb-home"
        onClick={handleCrumbClick}
        to="/home"
        className={clsx(classes.crumb, classes.crumbLink)}
      >
        Home
      </RouterLink>
      <RouterLink
        id="details-page-breadcrumb-results"
        to={{
          pathname: '/our-florists',
          search: params.toString(),
        }}
        onClick={handleCrumbClick}
        className={clsx(classes.crumb, classes.crumbLink)}
      >
        Our Florists
      </RouterLink>
      <Typography color="primary" className={classes.crumb}>
        {shopName ? shopName : 'Details'}
      </Typography>
    </Breadcrumbs>
  );
  const { shopName } = listing;
  return (
    <>
      <ShopName itemProp="name">{shopName}</ShopName>
      <Crumbs />
    </>
  );
};

export default TitleBar;

const ShopName = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 12px;
  padding: 0;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 43px;
    font-weight: 600;
    margin: 0px 0 -10px 0;
    padding: 0;
  }
`;
