import React from 'react';
import { copyObj } from '../utilities/javascripts';
import { format, set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export interface AutocompletePrediction extends google.maps.places.AutocompletePrediction {}

export interface IListing {
  shopCode: string; //      Ex : 'S35100007';
  shopName: string; //      Ex : 'The After Hours Flowers'
  consumerImage: string; // Ex : 'RandomAd2.png';

  shopAddress1: string; //  Ex : '306 W. 94th St.'
  shopAddress2: string; //  Ex : 'Apt 123'
  shopCity: string; //      Ex : 'NEW YORK',
  shopState: string; //     Ex : 'NY'
  shopZip: string; //       Ex : '10025'

  shopContact: string; //   Ex : 'Cedric Hubert';
  shopPhone: string; //     Ex : '555-555-1234';
  shopFax: string; //       Ex : '555-555-1234';
  shopWebsite: string; //   Ex : 'www.shop.com'
  shopLogo: string; //      Ex : 'RandomAd2.png'

  // Facebook, Twitter, LinkedIn, Instagram, Pinterest
  socialMedia: string; //           Ex : 'Facebook: www.facebook.com/S3510000,Twitter: Twitter.com/S3510000,LinkedIn: linkedin.com/S3510000'

  shopCustomProducts: string; //    Ex : 'productName: What an Arrangement!,productPrice: $60.00,productImage: 16405.jpg;productName: Ohhhh Pretty!,productPrice: $49.99,productImage: 17772.jpg;'
  shopProductMinimums: string; //   Ex : 'Arrangements: $25.00,Blooms: $25.00,Dozen Roses: $55.00,Fruit: $100.00,Gourmet Foods: $100.00'
  shopDeliveryFee: string; //       Ex : '0.0'
  shopMinimumOrder: string; //      Ex : '75.0'

  // IDs of every facility serviced, comma separated
  facilitiesServiced: string; //    Ex : '73266,76819,72873,79226,81022,79803'

  // Listing used for API ONLY use Shop for Display
  listingType: string; //           Ex : 'R' - Residential : 'X' - Extra
  listingCity: string; //           Ex : 'NEW YORK'
  listingStateCode: string; //      Ex : 'NY'
  listingState: string; //          Ex : 'NEW YORK'

  listingDate: string; //           Ex : "14-FEB"
  listingDeliveryCharge: string; // Ex : '0.0'
  listingAdSize: string; //         Ex : "F"
  listingBid: string; //            Ex : "999.0"
  listingCitySponsorAd: string; //  Ex : "true"
  listingCityPreferredAd: string; //  Ex : "true"
  listingMinimum: string; //        Ex : '54.99'

  // FRONT END ONLY not returned by API
  adPriorityIndex: number; // Keep track of the Ad Priority so it can be sorted on front end
  position: google.maps.LatLng | null; // Used to keep track of Lat, Lng Position
  isOpen: boolean; // Calculated when merging Listing and Member Data
}

export interface IFacility {
  facilityID: string; //        Ex : '52829'
  facilityName: string; //      Ex : 'Abbey Funeral Directors Inc'
  facilityType: string; //      Ex : 'FUNERAL HOME'
  facilityPhone: string; //     Ex : '555-555-0905'
  facilityAddress1: string; //  Ex : '2284 7th Ave'
  facilityAddress2: string; //  Ex : 'Apt. 1'
  facilityCity: string; //      Ex : 'NEW YORK'
  facilityState: string; //     Ex : 'NEW YORK'
  facilityZip: string; //       Ex : '10030'
}

export interface ICountry {
  countryShortName: string; //  Ex : 'AGO'
  countryName: string; //       Ex : 'ANGOLA'
}

export interface IState {
  stateShortName: string; //    Ex : 'AL'
  stateName: string; //         Ex : 'ALABAMA'
}

export interface ICity {
  cityName: string; //          Ex : 'ACCORD'
}

export interface ILocation extends IState, ICity {}

export interface IErrorMessage {
  errorMessage: string; //           EX: 'Unauthorized attempt.' or 'No results were found.'
}

export interface IMemberResponse {
  memberDirectoryInterface: IMemberDirectory;
}

export interface IMemberDirectory {
  searchShopResponse: ISearchShopResponse;
}

export interface ISearchShopResponse {
  errors: IMemberErrors;
  shops: IMemberShops;
}

export interface IMemberErrors {
  error: IMemberError | Array<IMemberError>;
}

export interface IMemberError extends IErrorMessage {
  errorCode: number;
  detailedErrorCode: number;
  errorMessage: string;
}

export interface IMemberShops {
  shop: IMemberShop | Array<IMemberShop>;
}

export interface IOperationalHours {
  [index: string]: IMemberShopSchedule;
  normalSchedule: IMemberShopSchedule;
  holidaySchedule: IMemberShopSchedule;
}

export interface IMemberShop {
  shopCode: string; //              EX : "F4290000"
  name: string; //                  EX : "Bloomin Genius Exotic Flowers"
  address: IMemberShopAddress;
  sundayIndicator: string; //       EX : "Y"
  phoneNumber: string | number; //  EX : 555551234
  phoneExt: any; // TODO            EX : {}
  faxNumber: string | number; //    EX : 555551234
  email: string; //                 EX : "EMAIL@EMAIL.COM"
  servicedZips: IMemberShopZips;
  nonDeliveryDates: any; // TODO    EX : {}
  shopStatus: string; //            EX : "A"
  communicationCode: number; //     EX : 4
  operationalHours: IOperationalHours;
  floristOfferingTypes: IMemberShopOfferingTypes;
}

export interface IMemberShopAddress {
  attention: string; //     EX : "CARRIE SOUTH"
  addressLine1: string; //  EX : "212 OUTLET WAY"
  addressLine2: string; //  EX : "STE H"
  city: string; //          EX : "NEW YORK"
  state: string; //         EX : "NY"
  zip: string | number; //  EX : '11111'
  zipPlus4: any; // TODO    EX : {}
  countryCode: string; //   EX : "USA"
  timeZone: string; //      EX : "EAST"
}

export interface IMemberShopZips {
  zip: Array<number>; //    EX : [11111,22222,33333,444444,55555]
}

export interface IDailyHours {
  openTime: string;
  closeTime: string;
}

export interface IMemberShopSchedule {
  [key: string]: IDailyHours;
  sunday: IDailyHours;
  monday: IDailyHours;
  tuesday: IDailyHours;
  wednesday: IDailyHours;
  thursday: IDailyHours;
  friday: IDailyHours;
  saturday: IDailyHours;
}

export interface IMemberShopOfferingTypes {
  offeringType: number | Array<number>; //    EX : 1
}

export interface IListingMemberShop extends IListing, IMemberShop {}

// Used in parsing Google api results.
export interface ILocInfo {
  city: string; //          EX : 'NEW YORK'
  state_long: string; //    EX : 'NEW YORK'
  state_short: string; //   EX : 'NY'
  postal_code: string; //   EX : '10025'
}

export interface ISignatureProduct {
  productName: string; //             EX : 'MEGA Boquet'
  productPrice: string; //            EX : '$89.99'
  productImage: string; //            EX : 'MegaBoquetB5230000.jpg'
  shopCity: string; //                EX : 'NEW YORK'
  shopState: string; //               EX : 'NY'
  shopCode: string; //                EX : 'S35100007'
  shopWebsite: string | null;  //      EX : 'www.R188.com'
  listing: IListingMemberShop;
  loc: ILocInfo;
}

export interface ICustomProduct {
  [key: string]: string;
  productName: string; //             EX : 'MEGA Boquet'
  productPrice: string; //            EX : '$89.99'
  productImage: string; //            EX : 'MegaBoquetB5230000.jpg'
}

export type BaseWrapperProps = {
  className: string;
  children: React.ReactNode;
  isOpen?: boolean;
};

export type CallGetListingsProps = {
  loc: ILocInfo;
  setLocalShops: React.Dispatch<React.SetStateAction<Array<IListingMemberShop>>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
};

export type FetchLocationProps = {
  coords: string;
  setLoc: React.Dispatch<React.SetStateAction<ILocInfo>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
};

export interface ILocationType {
  id: string;
  name: string;
}

export interface ComboProps {
  id: string;
  required: boolean;
  options: any[];
  optionId: string;
  optionLabel: string;
  inputLabel?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  value: any;
  setValue: React.Dispatch<React.SetStateAction<any | null>>;
  defaultValue?: any | null;
  disableUnderline: boolean;
  variant: 'outlined' | 'filled';
  size: 'small' | 'medium';
  className?: string;
  onKeyPress?: React.KeyboardEventHandler<any>;
}

export interface IFacilityTable {
  facilityName: string;
  address: string;
  facilityType: string;
  facilityID: string;
}

export interface ISocialLinks {
  [key: string]: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  pinterest: string;
  yelp: string;
}

export interface ShopCodeParam {
  shopCode: string;
}

export interface IAdvertisement {
  adShape?: string; //                    EX : "S"; possible values are S (square) and R (rectangle)
  fileName: string; //                    EX : "testfile1.jpg"
  website: string | undefined; //         EX : "www.testsite1.com"
}
export interface IHeaderInfo {
  cityName: string | undefined; //                    EX : 'NEW YORK'
  facilityName: string | undefined; //                EX : 'Abbey Funeral Directors Inc'
  resultsText?: string;
}

export interface IRouterState {
  listingState: IListingMemberShop | undefined;
  headerState: IHeaderInfo | undefined;
}

// Type Utilities

/**
 * Is object type IErrorMessage
 * @param object
 */
export const isErrorMessage = (object: any): object is IErrorMessage => 'errorMessage' in object;

/**
 * Is object type IMemberResponse
 * @param object
 */
export const isMemberResponse = (object: any): object is IMemberResponse =>
  'memberDirectoryInterface' in object;

/**
 * Converts time to a UTC Date object.
 * @param hours An array with either the shop's opening or closing time. EX : ["09:00", "AM"]
 * @param timeZone The member listing timezone, to convert to IANA timezone designation.
 */
interface ITimeCode {
  [key: string]: string;
}

// The API's current time zone entry is inexact; a more precise API would require more precise IANA codes.
const ianaTimeCode: ITimeCode = {
  EAST: 'America/New_York',
  CENTRAL: 'America/Chicago',
  MOUNTAIN: 'America/Denver',
  PACIFIC: 'America/Los_Angeles',
  INTERNATIONAL: 'intl',
  AKDT: 'America/Anchorage',
  HAST: 'Pacific/Honolulu',
};

/**
 * Helper function, see below.Returns an IANA time code.
 * @param timeZone The members address Time Zone
 * @param state The members address State. We use state for AK and HI because they are listed as international.
 */
const getTimeCode = (timeZone: string, state: string): string => {
  if (state === 'AK') return ianaTimeCode.AKDT;
  else if (state === 'HI') return ianaTimeCode.HAST;
  else return ianaTimeCode[timeZone];
};

/**
 * Helper function, see below. Returns a timestamp usable by the conversion fns below.
 * @param time the member shops listed opening or closing time
 * @param isAM whether or not the time is AM or PM (converting to 24 hour time)
 */
const getTimeStamp = (time: string, isAM: boolean) => {
  let result = [];
  if (time.slice(0, 2) === '12') {
    result[0] = +(isAM ? '00' : time.slice(0, 2));
  } else {
    result[0] = +(isAM ? time.slice(0, 2) : (+time.slice(0, 2) + 12).toString());
  }
  result[1] = +time.slice(2);

  const dt = set(new Date(), { hours: result[0], minutes: result[1], seconds: 0 });
  const stamp = format(dt, "yyyy'-'MM'-'dd' 'HH':'mm'");
  return stamp;
};

/**
 * Helper function, converts the time object into a UTC object based on the origin timezone or state.
 * @param hours member shop's opening and closing hours
 * @param timeZone member shop's listed time zone
 * @param state member shop's listed state
 */
const convertTime = (hours: Array<string>, timeZone: string, state: string) => {
  const time = hours[0].replace(':', '');
  const isAM = hours[1] === 'AM' ? true : false;

  const timeStamp = getTimeStamp(time, isAM);
  const timeCode = getTimeCode(timeZone, state);
  return zonedTimeToUtc(timeStamp, timeCode);
};

/**
 * Takes the shops normal operations hours and finds the opening and closing time
 * for the specific date and time of the user. Uses helper functions above. Specifically uses
 * operationlHours, timezone and state -- but uses a copy of the member object to avoid mutation.
 * @param member The specific member shop.
 */
const checkIfOpen = (member: IMemberShop) => {
  if (!member.operationalHours || !member.address.timeZone || !member.address.state) {
    return false;
  }
  const memCopy = copyObj(member);
  const { operationalHours } = memCopy;
  const { timeZone, state } = memCopy.address;
  // Current Time
  const tempNow = new Date();
  // use helper fns above to convert the time
  const day = format(tempNow, 'EEEE').toLowerCase();
  const tempShopHours = operationalHours.normalSchedule[day];
  if (!tempShopHours || !tempShopHours.openTime || !tempShopHours.closeTime) {
    return false;
  }
  const openingISO = convertTime(tempShopHours.openTime.split(' '), timeZone, state);
  const closingISO = convertTime(tempShopHours.closeTime.split(' '), timeZone, state);
  return tempNow > openingISO && tempNow < closingISO;
};

/**
 * Merge Listings and Shops only if its in both list
 * @param listings
 * @param shops
 */
export const mergeListingAndMembers = (
  listings: Array<IListing>,
  shops: Array<IMemberShop>,
): Array<IListingMemberShop> => {
  let merged = [] as Array<IListingMemberShop>;
  for (let i = 0; i < listings.length; i++) {
    const listing = listings[i];
    listing.adPriorityIndex = i; // Keep track of Ad Priority for sorting
    const member = shops.find((shop) => shop.shopCode === listings[i].shopCode); // Not found means shop is Closed
    listing.isOpen = // Checks if the shop is open by comparing current time and shop's open and closing times; World Florist is always open.
      listing.shopCode === 'A9999999'
        ? true
        : !!member?.operationalHours
        ? checkIfOpen(member)
        : false;
    merged.push({
      ...listing,
      ...member,
    } as IListingMemberShop);
  }
  return merged;
};

export const soloListings = (listings: Array<IListing>): Array<IListingMemberShop> => {
  let merged = [] as Array<IListingMemberShop>;
  for (let i = 0; i < listings.length; i++) {
    const listing = listings[i];
    listing.adPriorityIndex = i; // Keep track of Ad Priority for sorting
    const member = {} as IMemberShop;
    listing.isOpen = !!member?.operationalHours || listing.shopCode === 'A9999999'; // No member or Operational Hours means closed or World Florist open 24/7
    merged.push({
      ...listing,
      ...member,
    } as IListingMemberShop);
  }
  return merged;
};
