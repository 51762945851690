import * as React from 'react';
import PopularShopCard from './PopularShopCard';
import SignatureArrangements from './SignatureArrangements';
import { IListingMemberShop, ISignatureProduct, ILocInfo } from '../../../types/types';
import { VertOrHoriz } from '../../../components';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  popularRow: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    overflowX: 'auto',
    padding: '0 0 10px',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
}));

interface IResults {
  loc: ILocInfo;
  localShops: Array<IListingMemberShop> | null;
  signatureArrangements: Array<ISignatureProduct>;
}

const Results: React.FC<IResults> = ({
  localShops,
  signatureArrangements,
  loc,
}): React.ReactElement => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.popularRow}>
        {localShops &&
          localShops.map((data, idx) => {
            return (
              <React.Fragment key={idx}>
                <PopularShopCard key={data.shopCode} listing={data} idx={idx} loc={loc} />
                {localShops.length > 1 ? <VertOrHoriz idx={idx} /> : null}
              </React.Fragment>
            );
          })}
      </div>
      {signatureArrangements.length > 0 ? (
        <SignatureArrangements signatureArrangements={signatureArrangements} />
      ) : null}
    </>
  );
};

export default Results;
