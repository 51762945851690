import * as React from 'react';
import HeroImage from './HeroImage';
import AdSpace from './AdSpace';
import PopularSection from './popular-section/PopularSection';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../utilities/api';
import { IAdvertisement } from '../../types/types';
import { baseImageUrl } from '..';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  heroWrapper: {
    width: '100%',
    margin: '0 0 35px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0 100px',
    },
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignSelf: 'center',
    margin: '0 0 20px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '100px',
      paddingRight: '100px',
      margin: '0 0 60px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1400px',
    },
  },
}));

export interface HomePageProps {
  setHeaderContent: React.Dispatch<React.SetStateAction<string>>;
}

const HomePage: React.FC<HomePageProps> = ({ setHeaderContent }) => {
  const classes = useStyles();

  React.useEffect(() => {
    setHeaderContent('home');
  }, [setHeaderContent]);

  const [rectangleAdvertisements, setRectangleAdvertisements] = React.useState<Array<
    IAdvertisement
  > | null>(null);
  const [squareAdvertisements, setSquareAdvertisements] = React.useState<Array<
    IAdvertisement
  > | null>(null);
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    api
      .getLandingPageAds()
      .then((res) => {
        if (res.data.length > 0) {
          const tempRectangles: Array<IAdvertisement> = [] as Array<IAdvertisement>;
          const tempSquares: Array<IAdvertisement> = [] as Array<IAdvertisement>;
          const { data } = res;
          data.forEach((ad: IAdvertisement) => {
            const temp = { ...ad };
            temp.fileName = baseImageUrl.concat(ad.fileName);
            if (ad.adShape === 'R') {
              tempRectangles.push(temp);
            } else {
              tempSquares.push(temp);
            }
          });
          if (!!tempRectangles.length) setRectangleAdvertisements(tempRectangles);
          if (!!tempSquares.length) setSquareAdvertisements(tempSquares);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.log(err);
        else return;
      });

    return () => source.cancel('Cancelling API call');
  }, []);

  const adProps = { rectangleAdvertisements, squareAdvertisements };

  return (
    <>
      <div className={classes.heroWrapper}>
        <HeroImage />
      </div>
      <div className={classes.wrapper} id="PopularSection">
        <PopularSection />
      </div>
      <div className={classes.wrapper}>
        <AdSpace {...adProps} />
      </div>
    </>
  );
};

export default HomePage;
