import * as React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { HomePage, OurFloristsPage, FloristDetailsPage, PageNotFound } from './pages';
import { Header, Footer } from './components';
import cherryBlossomsRight from './assets/cherryBlossomsRight.png';
import cherryBlossomsLeft from './assets/cherryBlossomsLeft.png';
import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga4';
import { gaID } from './utilities/googleAnalytics';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  floristDetails: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },
  footerWrapper: {
    marginTop: 'auto',
    width: '100%',
    backgroundColor: '#F7F7F7',
    height: '50px',
    [theme.breakpoints.up('sm')]: {
      height: '150px',
    },
  },
}));

const App = () => {
  const classes = useStyles();
  const location = useLocation();
  const [headerContent, setHeaderContent] = React.useState<string>('home');

  const headerProps = { headerContent };
  const homeProps = { setHeaderContent };
  const floristsProps = { setHeaderContent };
  const selectedFloristProps = { setHeaderContent };

  const [isGAInitialized, setIsGAInitialized] = React.useState<boolean>(false);

  /**
   * This Effect initializes the Google Analytics tracking for the entire site / session.
   * Dependent GA tracking transmissions should check that the tracker has been initialized
   * before attempting to send data. If additional trackers are going to be used (e.g., for
   * marketing campaigns), include each tracker separately in the array.
   */
  React.useEffect(() => {
    if (isGAInitialized) return;

    try {
      ReactGA.initialize(gaID);
      setIsGAInitialized(true);
    } catch (err) {
      // TODO - Handle / Log error.
      if (process.env.NODE_ENV === 'development') console.log(err);
      else return;
    }
  }, [isGAInitialized]);

  /**
   * This Effect, due to it's position, will capture all pageviews in relation
   * to the location.pathname. There is / should be no need to include any pageview
   * data collection anywhere else on the site. This includes the specific shopcode
   * of the florist details page.
   */
  React.useEffect(() => {
    if (!isGAInitialized) return;

    try {
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
    } catch (err) {
      // TODO - Handle / Log error.
      if (process.env.NODE_ENV === 'development') console.log(err);
      else return;
    }
  }, [location.pathname, isGAInitialized]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Consumer Directory Online</title>
        <meta name="description" content="Get fresh flowers delivered to your loved ones!" />
        <meta property="og:url" content={window.location.origin} />
        <meta
          property="og:image"
          content={`${window.location.origin}/android-chrome-256x256.png`}
        />
        <link rel="canonical" href={window.location.origin} />
      </Helmet>
      <div className={classes.fullWidth}>
        <Header {...headerProps} />
        <Switch>
          <Route exact path="/home">
            <BackgroundDecoration>
              <HomePage {...homeProps} />
            </BackgroundDecoration>
          </Route>
          <Route exact path="/our-florists">
            <OurFloristsPage {...floristsProps} />
          </Route>
          <Route exact path="/our-florists/:shopCode">
            <div className={classes.floristDetails}>
              <FloristDetailsPage {...selectedFloristProps} />
            </div>
          </Route>
          <Redirect exact from="/" to="/home" />
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </div>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </div>
  );
};

export default App;

const backgrounds = `url(${cherryBlossomsRight}) no-repeat right 0px top calc(100vh), url(${cherryBlossomsLeft}) no-repeat left 0px top calc(100vh)`;

const BackgroundDecoration = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @media only screen and (min-width: 768px) {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
      ${backgrounds};
  }
  @media only screen and (min-width: 960px) {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)),
      ${backgrounds};
  }
  @media only screen and (min-width: 1600px) {
    background: ${backgrounds};
  }
`;
