import * as React from 'react';
import { IFacility, IErrorMessage, IFacilityTable } from '../../../types/types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

export interface FacilitiesProps {
  facilities: IFacility[];
  facilitiesError: IErrorMessage[];
  tableData: IFacilityTable[];
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '0 0 50px 0',
    '& .MuiTableCell-body': {
      whiteSpace: 'pre',
    },
  },
  container: {
    maxHeight: '100%',
  },
  noResults: {
    fontSize: '18px',
    textAlign: 'center',
    margin: '20px 0 0 0',
  },
});

const columns = [
  {
    name: 'facilityName',
    label: 'Facility Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'address',
    label: 'Address',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'facilityType',
    label: 'Facility Type',
    options: {
      filter: true,
      sort: true,
    },
  },
];

const options = {
  download: false,
  selectableRows: 'none' as const,
  sortOrder: {
    name: 'facilityName',
    direction: 'asc' as const,
  },
};

const FacilitiesTab: React.FC<FacilitiesProps> = ({
  facilities,
  facilitiesError,
  tableData,
}): React.ReactElement => {
  const classes = useStyles();

  if (!!facilitiesError.length || !facilities.length)
    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.noResults}>
          Sorry, no facilities were found.
        </Typography>
      </div>
    );
  else
    return (
      <div className={classes.root}>
        <MUIDataTable
          title="Facilities Serviced"
          data={tableData}
          columns={columns}
          options={options}
        />
      </div>
    );
};

export default FacilitiesTab;
