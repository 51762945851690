import * as React from 'react';
import { useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axios from 'axios';
import api from '../../utilities/api';
import { parseISO } from 'date-fns';
import { Hidden } from '@material-ui/core';
import { IAdvertisement, IListingMemberShop, ILocation, IRouterState } from '../../types/types';
import MobileSearch from './MobileSearch';
import MapBox from './MapBox';
import ResultsHeader from './ResultsHeader';
import FloristResults from './FloristResults';
import APIErrorResponse from '../../components/forms/APIErrorResponse';
import { noSearchResultsGA } from '../../utilities/googleAnalytics';
import FloristAdSpace from './FloristAdSpace';
import { baseImageUrl } from '..';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'center',
    height: 'calc(100vh - 65px)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 80px)',
    },
  },
  resultsCol: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'inherit',
    overflow: 'hidden',
  },
  mapCol: {
    width: '100%',
    height: 'inherit',
    padding: '10px',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  mapWrapper: {
    width: '100%',
    padding: '0 0 10px 0',
    flex: '1',
  },
  adWrapper: {
    maxHeight: '182px',
    width: '100%',
  },
}));

export interface OurFloristsProps {
  setHeaderContent: React.Dispatch<React.SetStateAction<string>>;
}

const OurFloristsPage: React.FC<OurFloristsProps> = ({ setHeaderContent }) => {
  const classes = useStyles();
  const location = useLocation();
  const [shopSelected, setShopSelected] = React.useState<string | null>(null);
  const [shopHovered, setShopHovered] = React.useState<string | null>(null);
  const [searchLocation, setSearchLocation] = React.useState<ILocation>({
    cityName: '',
    stateName: '',
    stateShortName: '',
  });

  // Set Header Type
  React.useEffect(() => setHeaderContent('florists'), [setHeaderContent]);

  // React-Router State
  const defaultRouterState: IRouterState = {
    listingState: undefined,
    headerState: undefined,
  };

  const routerState =
    location.state !== undefined ? (location.state as IRouterState) : defaultRouterState;
  const { listingState, headerState } = routerState;
  const shop = listingState !== undefined ? [listingState as IListingMemberShop] : [];

  // Error State to conditionally render APIErrorResponse.
  // Only triggers after an error is generated.
  const [showError, setShowError] = React.useState<boolean>(false);
  const [listings, setListings] = React.useState<Array<IListingMemberShop>>(shop);

  /**
   * Results Page Ads
   */
  const [advertisements, setAdvertisements] = React.useState<Array<IAdvertisement> | null>(null);
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const params = new URLSearchParams(location.search);
    const cityParam = params.get('city') || '';
    const stateParam = params.get('state') || '';

    api
      .getResultsPageAds(cityParam, stateParam)
      .then((res) => {
        if (res.data.length > 0) {
          const tempAds: Array<IAdvertisement> = [] as Array<IAdvertisement>;
          const { data } = res;
          data.forEach((ad: IAdvertisement) => {
            const temp = { ...ad };
            temp.fileName = baseImageUrl.concat(ad.fileName);
            tempAds.push(temp);
          });
          setAdvertisements(tempAds);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.log(err);
        else return;
      });

    return () => source.cancel('Cancelling API call');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get Listings
   */
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const len = shop.length;

    // URL Parameters
    const params = new URLSearchParams(location.search);
    const cityParam = params.get('city') || '';
    const stateParam = params.get('state') || '';
    const stateCodeParam = params.get('sc') || '';
    const locationTypeParam = params.get('locationType') || '';
    const dateParam = params.get('date');
    const facilityParam = params.get('facility');

    // Search Fields
    const date = dateParam ? parseISO(dateParam) : new Date();
    const facility =
      locationTypeParam === 'null' ? null : facilityParam ? Number(facilityParam) : null;

    const getDefault = () => {
      const params = new URLSearchParams(location.search);
      noSearchResultsGA(params.toString());

      const dateParam = params.get('date');
      const date = dateParam ? parseISO(dateParam) : new Date(); // EX. YYYY-MM-DD
      api
        .getWorldFlowers(date)
        .then((data) => {
          if (data.length > 0) {
            setListings(data);
          } else {
            setShowError(true);
            setListings([]);
          }
        })
        .catch(() => {
          setShowError(true);
          setListings([]);
        });
    };

    if (cityParam === '' || stateParam === '') {
      // TODO - Handle Missing Data
      getDefault();
      return;
    }

    searchLocation.cityName = cityParam;
    searchLocation.stateName = stateParam;
    searchLocation.stateShortName = stateCodeParam;
    setSearchLocation(searchLocation);

    /**
     * Allow params to be built, but abandon if there is already data on state.
     * Necessary for proper "back-button" navigation.
     */
    if (len > 0) {
      // List of data already send to page
      return;
    }

    // GET Listings and Member Shop List if Router State is empty
    api
      .getListingsAndMemberShops(searchLocation, facility, date)
      .then((data) => {
        if (data.length > 0) {
          setListings(data);
        } else {
          // Empty Results so get default World Florist
          getDefault();
        }
      })
      .catch(() => {
        // Error so try to get default World Florist
        getDefault();
      });

    return () => source.cancel('Cancelling API call');
  }, [location, shop.length, searchLocation]);

  return (
    <div className={classes.root}>
      <div className={classes.resultsCol}>
        <MobileSearch />
        <ResultsHeader
          headerState={headerState}
          count={listings.length}
          city={searchLocation.cityName}
          state={searchLocation.stateName}
        />
        {showError ? (
          <APIErrorResponse />
        ) : (
          <FloristResults listings={listings} setShopHovered={setShopHovered} />
        )}
      </div>

      <Hidden xsDown>
        <div className={classes.mapCol}>
          <div className={classes.mapWrapper}>
            <MapBox
              listings={listings}
              shopSelected={shopSelected}
              setShopSelected={setShopSelected}
              shopHovered={shopHovered}
              showError={showError}
            />
          </div>
          <div className={classes.adWrapper}>
            <FloristAdSpace advertisements={advertisements} />
          </div>
        </div>
      </Hidden>
    </div>
  );
};

export default OurFloristsPage;
