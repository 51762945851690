import * as React from 'react';
import { IListingMemberShop } from '../../types/types';
import FloristCard from './card/FloristCard';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { List, ListItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: '1 1',
    overflowY: 'auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
    },
  },
}));

export interface FloristResultsProps {
  listings: Array<IListingMemberShop>;
  setShopHovered: React.Dispatch<React.SetStateAction<string | null>>;
}

const FloristResults: React.FC<FloristResultsProps> = ({ listings, setShopHovered }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sortedList, setSortedList] = React.useState<Array<IListingMemberShop>>(listings);
  const delayHandler = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (listings) {
      setSortedList(listings.sort(sortListings));
    }
  }, [listings]);

  /**
   * Display Open shops first then sort by higher paid ad
   * @param a
   * @param b
   */
  const sortListings = (a: IListingMemberShop, b: IListingMemberShop): number => {
    if (a.isOpen === b.isOpen) return a.adPriorityIndex - b.adPriorityIndex;
    return a.isOpen ? -1 : 1;
  };

  const onFloristCardMouseEnter = React.useCallback(
    (shopCode: string) => {
      if (delayHandler.current) {
        clearTimeout(delayHandler.current);
      }
      delayHandler.current = setTimeout(() => setShopHovered(shopCode), 250);
    },
    [setShopHovered],
  );

  const onFloristCardMouseLeave = React.useCallback(() => {
    if (delayHandler.current) {
      clearTimeout(delayHandler.current);
    }
    delayHandler.current = setTimeout(() => setShopHovered(null), 250);
  }, [setShopHovered]);

  const listCount = sortedList.length;

  return (
    <div className={classes.content}>
      <List className={classes.list}>
        {sortedList.map((listing, idx) => (
          <ListItem
            divider={isMobile && idx + 1 < listCount}
            key={listing.shopCode}
            onMouseEnter={!isMobile ? () => onFloristCardMouseEnter(listing.shopCode) : undefined}
            onMouseLeave={!isMobile ? () => onFloristCardMouseLeave() : undefined}
          >
            <FloristCard listing={listing} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default FloristResults;
