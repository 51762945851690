import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    md2: true;
    lg: true;
    xl: true;
  }
}

export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      'Lato',
      'sans-serif',
      '-apple-system',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      // orange
      light: '#ffaf42',
      main: '#e97f02',
      dark: '#b05100',
      contrastText: '#ffffff',
    },
    secondary: {
      // purple
      light: '#aba5a5',
      main: '#5A3DEF',
      dark: '#504a4a',
      contrastText: '#ffffff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      md2: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
});
