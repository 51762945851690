import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ComboProps } from '../../types/types';

/**
 * Autocomplete / Drop Down
 * @Options are stored in: src/utilities/autocomplete-utils.tsx
 *
 * @required Boolean indicating whether or not the field is treated as required.
 * @id The string id of the element.  // EX : 'facility-combo-box'
 * @options An array used to populate the drop down.
 *          // EX : const options = [{ value: 1, label: 'Residential' } ... ]
 *          // EX : const options = ['one', 'two', 'three']
 * @optionLabel The array element that serves as the label the user sees.
 *          // EX : { ..., label: 'Residential'}
 *          // EX : options[0]
 * @inputLabel The TextField label. //  EX : 'Location Type'
 * @value the "value" state with the value/onChange props combination. This
 *        state represents the value selected by the user, for instance
 *        when pressing Enter.
 * @setValue The onChange setter for value.
 * @inputValue the "input value" state with the inputValue/onInputChange
 *             props combination. This state represents the value displayed
 *             in the textbox.
 * @setInputValue The onInputChange setter for inputValue.
 * @note value and inputValue states are isolated, they should be controlled independently.
 */
const ComboBox = (props: ComboProps): JSX.Element => {
  const {
    id,
    required,
    options,
    optionId,
    optionLabel,
    inputLabel,
    placeholder,
    error,
    helperText,
    value,
    setValue,
    defaultValue,
    disableUnderline,
    variant,
    size,
    className,
    onKeyPress,
  } = props;

  /**
   * There is a known issue with Typescript and using props to change the style
   * of the TextField input (variant).
   * See: https://github.com/mui-org/material-ui/issues/15697
   */
  return (
    <Autocomplete
      id={id}
      blurOnSelect={true}
      clearOnBlur={true}
      options={options}
      getOptionSelected={(option, value) => {
        return !!option && !!value && option[optionId] === value[optionId];
      }}
      getOptionLabel={(option) => {
        return !!option ? option[optionLabel] : '';
      }}
      className={className}
      value={value}
      defaultValue={defaultValue}
      onChange={(event: any, newValue: any | null) => {
        if (!!defaultValue && newValue === null) {
          newValue = defaultValue;
        }
        setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant as any}
          size={size as any}
          required={required}
          label={inputLabel}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
          InputProps={{
            ...params.InputProps,
            ...(disableUnderline ? { disableUnderline: true } : {}),
          }}
          onKeyPress={onKeyPress}
        />
      )}
    />
  );
};

export default ComboBox;
