import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FindFlorist, { SearchType } from '../../components/forms/FindFlorist';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '90vw',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    padding: '10px 20px 20px',
    margin: '5px auto',
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0px 0px 40px #0A0A0A1A',
      maxWidth: '720px',
      height: '400px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#FFFFFF',
    },
  },
  title: {
    fontSize: `20px`,
    fontWeight: 600,
    margin: '0 auto',
    textAlign: 'center',
    color: '#FFFFFF',
    '@media (min-width:480px)': {
      fontSize: `26px`,
    },
    [theme.breakpoints.up('sm')]: {
      lineHeight: '36px',
      color: '#000000',
    },
  },
}));

const GetFlowersTitle: React.FC = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <h1 className={classes.title}>Send fresh flowers to your loved ones!</h1>
    </Grid>
  );
};

const GetDelivery: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper} spacing={2}>
      <GetFlowersTitle />
      <FindFlorist type={SearchType.Default} />
    </Grid>
  );
};

export default GetDelivery;
