import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ISocialLinks } from '../../../types/types';
import fbblue from '../../../assets/social-media-icons/facebook-icons/fbblue.png';
import igwhite from '../../../assets/social-media-icons/instagram-icons/igwhite.png';
import pinred from '../../../assets/social-media-icons/pinterest-icons/pinred.png';
import twitblue from '../../../assets/social-media-icons/twitter-icons/twitblue.png';
import linkedblue from '../../../assets/social-media-icons/linkedin-icons/linkedblue.png';
import yelp from '../../../assets/social-media-icons/yelp-icons/yelp.png';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import GALink from '../../../components/links/GALink';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '40px 0 130px',
  },
  card: {
    padding: '4.325vw 4.325vw',
    margin: '0 0 10px',
    borderRadius: 5,
    backgroundColor: '#F5F5F5',
    filter: 'grayscale(100%)',
    '&:hover': {
      filter: 'grayscale(0)',
    },
  },
  noLink: {
    display: 'none',
  },
  social: {
    width: 50,
    height: 'auto',
  },
  wrapper: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsWrapper: {
    width: '100%',
    margin: '0 0 50px 0',
    '& .MuiTableCell-body': {
      whiteSpace: 'pre',
    },
  },
  noResults: {
    fontSize: '18px',
    textAlign: 'center',
    margin: '20px 0 0 0',
  },
  linkedin: {
    width: 37.5,
    height: 'auto',
    borderRadius: '20% 20% 15% 15%',
    padding: '0 0 5px',
  },
  instagram: {
    width: 37.5,
    height: 'auto',
    backgroundColor: '#f00075',
    borderRadius: '50%',
  },
  linkedinWrapper: {
    backgroundColor: '#2867b2',
  },
  instagramWrapper: {
    backgroundColor: '#f00075',
  },
}));

export interface SocialMediaProps {
  socialMediaLinks: ISocialLinks | null;
}

const SocialMediaTab: React.FC<SocialMediaProps> = ({ socialMediaLinks }): React.ReactElement => {
  const classes = useStyles();

  if (!socialMediaLinks) {
    return (
      <div className={classes.noResultsWrapper}>
        <Typography variant="h5" className={classes.noResults}>
          Sorry, shop currently has no social media.
        </Typography>
      </div>
    );
  } else {
    return (
      <Grid
        container
        direction="row"
        wrap="wrap"
        justify="space-between"
        alignItems="center"
        alignContent="space-between"
        className={classes.root}
      >
        <Grid item className={!!socialMediaLinks.facebook ? classes.card : classes.noLink}>
          <GALink
            eventLabel={`Florist's Facebook: ${socialMediaLinks.facebook}`}
            to={socialMediaLinks.facebook}
          >
            <div className={classes.wrapper}>
              <img src={fbblue} className={classes.social} alt="Facebook Icon" />
            </div>
          </GALink>
        </Grid>

        <Grid item className={!!socialMediaLinks.twitter ? classes.card : classes.noLink}>
          <GALink
            eventLabel={`Florist's Twitter: ${socialMediaLinks.twitter}`}
            to={socialMediaLinks.twitter}
          >
            <div className={classes.wrapper}>
              <img src={twitblue} className={classes.social} alt="Twitter Icon" />
            </div>
          </GALink>
        </Grid>

        <Grid item className={!!socialMediaLinks.linkedin ? classes.card : classes.noLink}>
          <GALink
            eventLabel={`Florist's LinkedIn: ${socialMediaLinks.linkedin}`}
            to={socialMediaLinks.linkedin}
          >
            <div className={clsx(classes.wrapper, classes.linkedinWrapper)}>
              <img src={linkedblue} className={classes.linkedin} alt="Linked-In Icon" />
            </div>
          </GALink>
        </Grid>

        <Grid item className={!!socialMediaLinks.instagram ? classes.card : classes.noLink}>
          <GALink
            eventLabel={`Florist's Instagram: ${socialMediaLinks.instagram}`}
            to={socialMediaLinks.instagram}
          >
            <div className={clsx(classes.wrapper, classes.instagramWrapper)}>
              <img src={igwhite} className={classes.instagram} alt="Instagram Icon" />
            </div>
          </GALink>
        </Grid>

        <Grid item className={!!socialMediaLinks.pinterest ? classes.card : classes.noLink}>
          <GALink
            eventLabel={`Florist's Pinterest: ${socialMediaLinks.pinterest}`}
            to={socialMediaLinks.pinterest}
          >
            <div className={classes.wrapper}>
              <img src={pinred} className={classes.social} alt="Pinterest Icon" />
            </div>
          </GALink>
        </Grid>
		
		<Grid item className={!!socialMediaLinks.yelp ? classes.card : classes.noLink}>
          <GALink
            eventLabel={`Florist's Yelp: ${socialMediaLinks.yelp}`}
            to={socialMediaLinks.yelp}
          >
            <div className={classes.wrapper}>
              <img src={yelp} className={classes.social} alt="Yelp Icon" />
            </div>
          </GALink>
        </Grid>
      </Grid>
    );
  }
};

export default SocialMediaTab;
