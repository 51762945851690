import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  IFacility,
  IErrorMessage,
  IFacilityTable,
  IListingMemberShop,
  ISocialLinks,
} from '../../../types/types';
import { getValidUrl } from '../../../utilities/javascripts';
import SocialMediaTab from './SocialMediaTab';
import DeliveryHoursTab from './DeliveryHoursTab';
import FacilitiesTab from './FacilitiesTab';
import DirectionsTab from './DirectionsTab';
import { internalNavButtonGA } from '../../../utilities/googleAnalytics';
import { makeStyles } from '@material-ui/core/styles';

const tabOpts = {
  deliveryHours: { label: 'Delivery-Hours', value: 0 },
  facilities: { label: 'Facilities', value: 1 },
  socialMedia: { label: 'Social-Media', value: 2 },
  directions: { label: 'Directions', value: 3 },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '0',
    [theme.breakpoints.up('sm')]: { margin: '10px 0 0' },
  },
  tab: {
    color: '#000000',
    opacity: '1',
    fontSize: '16px',
    lineHeight: '22px',
    textTransform: 'none',
    borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
    minHeight: '64px',
    minWidth: '140px',
    width: '25%',
  },
}));
export interface DetailsTabsProps {
  listing: IListingMemberShop;
  facilities: IFacility[];
  facilitiesError: IErrorMessage[];
  tableData: IFacilityTable[];
}

const DetailsTabs: React.FC<DetailsTabsProps> = ({
  listing,
  facilities,
  facilitiesError,
  tableData,
}): React.ReactElement => {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(tabOpts.deliveryHours.value);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [socialMediaLinks, setSocialMediaLinks] = React.useState<ISocialLinks | null>(null);
  React.useEffect(() => {
    if (!listing.socialMedia) return;

    const getSocialLinks = () => {
      const socialLinks = {} as ISocialLinks;
      const temp = listing.socialMedia.split(',');
      temp.forEach((link) => {
        const keyVal = link.split(':');
        const key = keyVal[0].trim().toLowerCase();
        const val = keyVal[1].trim();
        if (key === 'facebook' || 'twitter' || 'linkedin' || 'instagram' || 'pinterest')
          socialLinks[key] = getValidUrl(val);
      });
      return socialLinks;
    };

    setSocialMediaLinks(getSocialLinks());
  }, [listing.socialMedia]);

  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    internalNavButtonGA(e.currentTarget.id);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        <Tab
          className={classes.tab}
          label="Delivery Hours"
          value={tabOpts.deliveryHours.value}
          {...getAccessibilityProps(tabOpts.deliveryHours)}
          onClick={handleTabClick}
        />
        <Tab
          className={classes.tab}
          label="Facilities"
          value={tabOpts.facilities.value}
          {...getAccessibilityProps(tabOpts.facilities)}
          onClick={handleTabClick}
        />

        <Tab
          className={classes.tab}
          label="Social Media"
          value={tabOpts.socialMedia.value}
          {...getAccessibilityProps(tabOpts.socialMedia)}
          onClick={handleTabClick}
        />

        <Tab
          className={classes.tab}
          label="Directions"
          value={tabOpts.directions.value}
          {...getAccessibilityProps(tabOpts.directions)}
          onClick={handleTabClick}
        />
      </Tabs>

      <TabPanel value={value} index={tabOpts.deliveryHours.value}>
        <DeliveryHoursTab {...listing} />
      </TabPanel>
      <TabPanel value={value} index={tabOpts.facilities.value}>
        <FacilitiesTab
          facilities={facilities}
          facilitiesError={facilitiesError}
          tableData={tableData}
        />
      </TabPanel>
      <TabPanel value={value} index={tabOpts.socialMedia.value}>
        <SocialMediaTab socialMediaLinks={socialMediaLinks} />
      </TabPanel>
      <TabPanel value={value} index={tabOpts.directions.value}>
        <DirectionsTab {...listing} />
      </TabPanel>
    </div>
  );
};

export default DetailsTabs;

const getAccessibilityProps = (tab: any) => {
  return {
    id: `${tab.label}-navigation-tab`,
    'aria-controls': `${tab.label}-navigation-panel`,
  };
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
