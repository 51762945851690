import React from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import apiError from '../../assets/api-error.png';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '50px 0',
  },
  image: {
    margin: '0 0 20px',
  },
  text: {
    font: 'normal normal 500 15px/23px Poppins',
    maxWidth: 308,
    textAlign: 'center',
    margin: '0 0 25px',
  },
  tryAgain: {
    textTransform: 'none',
    font: 'normal normal 500 15px/23px Poppins',
    width: '135px',
    height: '52px',
    borderRadius: '5px',
  },
}));

const APIErrorResponse: React.FC<{}> = (): React.ReactElement => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={apiError} alt="API Error Icon" />
      <Typography className={classes.text}>
        We’re sorry, it looks like there’s something missing here.
      </Typography>
      <Button
        component={RouterLink}
        to={{
          pathname: location.pathname,
          search: location.search,
        }}
        className={classes.tryAgain}
        color="primary"
        variant="contained"
        disableElevation
      >
        Try again
      </Button>
    </div>
  );
};

export default APIErrorResponse;
