import { Hidden, Typography } from '@material-ui/core';
import * as React from 'react';
import flowerShop from '../../assets/flower_shop.png';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { titleCaser } from '../../utilities/javascripts';
import { IHeaderInfo } from '../../types/types';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    margin: '20px 0 0',
  },
  summary: {
    padding: '0 30px 0 30px',
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  summaryText: {
    padding: '0 15px',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
  },
  numberFlorists: {
    font: '600 15px/23px Poppins',
  },
  iconWrapper: {
    height: '65px',
    width: '65px',
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
  },
  flowerShopIcon: {
    objectFit: 'contain',
    maxWidth: '100%',
    height: 'auto',
  },
}));

export interface ResultHeaderProps {
  headerState: IHeaderInfo | undefined;
  count: number;
  city: string;
  state: string;
}

const ResultsHeader: React.FC<ResultHeaderProps> = ({ headerState, count, city, state }) => {
  const classes = useStyles();

  const buildText = () => {
    if (headerState && headerState.resultsText !== undefined) {
      return headerState.resultsText;
    } else {
      const location = !!headerState?.facilityName ? headerState.facilityName : `${city}, ${state}`;
      return `${count} Professional ${count > 1 ? 'Florists' : 'Florist'} Serving ${titleCaser(
        location,
      )}.`;
    }
  };

  const text = buildText();
  return (
    <Hidden smDown>
      <div className={classes.wrapper}>
        <div className={classes.summary}>
          <div className={classes.iconWrapper}>
            <img className={classes.flowerShopIcon} src={flowerShop} alt="Flower Shop Icon" />
          </div>
          <div className={classes.summaryText}>
            <Typography className={classes.numberFlorists}>{text}</Typography>
          </div>
        </div>
      </div>
    </Hidden>
  );
};

export default ResultsHeader;
