import * as React from 'react';
import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Button, Grid } from '@material-ui/core';
import { Type14Medium, PhoneLink, AddressLink } from '../../components';
import placeIcon from '../../assets/Place-icon.png';
import phone from '../../assets/phone.png';
import date_range from '../../assets/date_range.png';
import { IListingMemberShop } from '../../types/types';
import { getValidUrl } from '../../utilities/javascripts';
import { ShoppingBagIcon } from '../../components/index';
import GALink from '../../components/links/GALink';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
    margin: '0',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#F7F7F7',
      borderRadius: '10px',
    },
  },
  shopNowButton: {
    textTransform: 'none',
    fontFamily: '15px/23px Poppins',
    borderRadius: '5px',
  },
  detailFlex: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  flexLeft: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      padding: '0 0 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      padding: '0',
    },
  },
  flexRight: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      padding: '0 50px 0 0',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      padding: '0',
    },
  },
}));

const LocationDetailsRow: React.FC<IListingMemberShop> = ({
  shopAddress1,
  shopAddress2,
  shopCity,
  shopState,
  shopZip,
  shopPhone,
  shopWebsite,
  position,
  isOpen,
}): React.ReactElement => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.row}
      container
      direction="row"
      wrap="wrap"
      justify="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12} sm={6} md={3}>
        <div className={clsx(classes.detailFlex, classes.flexLeft)}>
          <IconBG>
            <DetailIcon src={placeIcon} alt="Location Marker Icon" style={{ maxWidth: '16px' }} />
          </IconBG>
          <div>
            <AddressLink
              id="_address"
              address1={shopAddress1}
              address2={shopAddress2}
              city={shopCity}
              state={shopState}
              zip={shopZip}
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={clsx(classes.detailFlex, classes.flexRight)}>
          <IconBG>
            <DetailIcon src={phone} alt="Phone Icon" />
          </IconBG>
          <PhoneLink id="_telephone" phone={shopPhone} />
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={clsx(classes.detailFlex, classes.flexLeft)}>
          <IconBG>
            <DetailIcon src={date_range} alt="Calendar Icon" />
          </IconBG>
          <OpenIndicator className="open-indicator" isOpen={isOpen}>
            <Type14Medium style={{ padding: `3px 10px`, color: '000' }}>
              <b>{isOpen ? 'Open' : 'Closed'}</b>
            </Type14Medium>
          </OpenIndicator>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={clsx(classes.detailFlex, classes.flexRight)}>
          {shopWebsite ? (
            <GALink
              style={{ textDecoration: 'none' }}
              eventLabel={`Florist Website: ${shopWebsite}`}
              to={getValidUrl(shopWebsite)}
            >
              <Button
                color="primary"
                disableElevation
                startIcon={<ShoppingBagIcon color="#ffffff" />}
                className={classes.shopNowButton}
                variant="contained"
              >
                Shop Now
              </Button>
            </GALink>
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
};

/*
height: 60px;
color: #ffffff;
background-color: #e97f02;
font: 15px/23px Poppins;
border-radius: 5px;
text-transform: none;
&:hover {
  background-color: #ff8b00;
  box-shadow: none;
}
*/

export default LocationDetailsRow;

// ********** FLORIST LOCATION CARDS && FLORIST DETAILS
const IconBG = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 0;
  background-color: transparent;
  min-height: 40px;
  min-width: 40px;
`;

const DetailIcon = styled.img`
  height: 24px;
  width: 24px;
  padding: 0;
`;

const BaseWrapper: React.FC<BaseWrapperProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
);

type BaseWrapperProps = {
  className: string;
  children: React.ReactNode;
  isOpen: boolean;
};

const OpenIndicator = styled(BaseWrapper)`
  background: ${(props) =>
    props.isOpen
      ? `rgba(125, 200, 131, .25) 0% 0% no-repeat padding-box`
      : `rgba(233, 2, 2, .12) 0% 0% no-repeat padding-box`};
  border-radius: 19px;
`;
