import * as React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import placeIcon from '../../../assets/Place-icon.png';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { AutocompletePrediction, IListingMemberShop } from '../../../types/types';
import PlaceAutoComplete from '../../../components/selects/GoogleMapPlaceAutoComplete';
import { customOutboundGA } from '../../../utilities/googleAnalytics';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: '40px 0',
  },
  noResultsWrapper: {
    width: '100%',
    margin: '0 0 50px 0',
    '& .MuiTableCell-body': {
      whiteSpace: 'pre',
    },
  },
  noResults: {
    fontSize: '18px',
    textAlign: 'center',
    margin: '20px 0 0 0',
  },
  getDirections: {
    textTransform: 'none',
    borderRadius: '5px',
    font: '500 14px/21px Poppins',
    padding: '15px 25px',
  },
}));

const DirectionsTab: React.FC<IListingMemberShop> = (listing): React.ReactElement => {
  const { shopAddress1, shopAddress2, shopCity, shopState, shopZip } = listing;
  const streetAddress = `${shopAddress1 ?? ''} ${shopAddress2 ?? ''}`;
  const fullShopAddress = `${streetAddress}, ${shopCity ?? ''}, ${shopState ?? ''} ${
    shopZip ?? ''
  }`;
  const classes = useStyles();
  const [userLocation, setUserLocation] = React.useState<AutocompletePrediction | null>(null);
  const [searchEndLocation] = React.useState<string>(fullShopAddress);
  const [error, setError] = React.useState<boolean>(false);
  const searchLoaded = React.useRef(false);

  const directionsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!userLocation) {
      setError(!userLocation);
    } else {
      window.open(
        `https://maps.google.com?saddr=${userLocation.description}&daddr=${fullShopAddress}`,
      );
      customOutboundGA(e.currentTarget.id);
    }
  };

  React.useEffect(() => {
    // Dont show error on first load
    if (!searchLoaded.current) {
      searchLoaded.current = true;
      return;
    }
    setError(!userLocation);
  }, [userLocation]);
  if (!shopAddress1 && ((!shopCity && !shopState) || !shopZip)) {
    return (
      <div className={classes.noResultsWrapper}>
        <Typography variant="h5" className={classes.noResults}>
          Sorry, no shop address was found.
        </Typography>
      </div>
    );
  } else {
    return (
      <Grid container className={classes.wrapper} justify="center">
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="start-location">Enter your starting address:</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <PlaceAutoComplete
                value={userLocation}
                setValue={setUserLocation}
                error={error}
                errorMessage="Starting address required"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="start-location">Shop Address:</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="start-location"
                value={searchEndLocation}
                readOnly={true}
                placeholder="Shop Address"
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src={placeIcon}
                      alt="Location Marker Icon"
                      style={{ maxWidth: '16px', height: '24px', width: '24px' }}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.getDirections}
              variant="contained"
              color="primary"
              disableElevation
              id="get-directions"
              onClick={directionsClick}
            >
              Get Directions
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default DirectionsTab;
