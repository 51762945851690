import * as React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import App from './App';
import { theme } from './theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as serviceWorker from './serviceWorker';
import { LoadScript } from '@react-google-maps/api';

import './index.css';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    let root = document.getElementById('root');
    if (!!root) root.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyBb5hzlBVnvdCfUW5ljZEC1e1nk0QMDcIQ"
        libraries={['places']}
      >
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </LoadScript>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
