import * as React from 'react';
import { IListingMemberShop } from '../../../types/types';
import { Type14Regular2B, Type14SemiBold2B } from '../../../components/font';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 0 40px 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  day: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    alignItems: 'center',
    marginTop: '25px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  dayHeader: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flex: '1',
      justifyContent: 'center',
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '1',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  dayHours: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flex: '1',
      justifyContent: 'center',
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '1',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  noResultsWrapper: {
    width: '100%',
    margin: '0 0 50px 0',
    '& .MuiTableCell-body': {
      whiteSpace: 'pre',
    },
  },
  noResults: {
    fontSize: '18px',
    textAlign: 'center',
    margin: '20px 0 0 0',
  },
}));

const DeliveryHoursTab: React.FC<IListingMemberShop> = ({
  operationalHours,
}): React.ReactElement => {
  const classes = useStyles();
  const hours = operationalHours?.normalSchedule || operationalHours?.holidaySchedule || null;
  if (!hours) {
    return (
      <div className={classes.noResultsWrapper}>
        <Typography variant="h5" className={classes.noResults}>
          Sorry, no delivery hours were found.
        </Typography>
      </div>
    );
  } else {
    return (
      <div className={classes.wrapper}>
        <div className={classes.day}>
          <div className={classes.dayHeader}>
            <Type14SemiBold2B>Monday</Type14SemiBold2B>
          </div>
          <div className={classes.dayHours}>
            <Type14Regular2B>
              {`${hours?.monday?.openTime || 'NA'} - ${hours?.monday?.closeTime || 'NA'}`}
            </Type14Regular2B>
          </div>
        </div>
        <div className={classes.day}>
          <div className={classes.dayHeader}>
            <Type14SemiBold2B>Tuesday</Type14SemiBold2B>
          </div>
          <div className={classes.dayHours}>
            <Type14Regular2B>
              {`${hours?.tuesday?.openTime || 'NA'} - ${hours?.tuesday?.closeTime || 'NA'}`}
            </Type14Regular2B>
          </div>
        </div>
        <div className={classes.day}>
          <div className={classes.dayHeader}>
            <Type14SemiBold2B>Wednesday</Type14SemiBold2B>
          </div>
          <div className={classes.dayHours}>
            <Type14Regular2B>
              {`${hours?.wednesday?.openTime || 'NA'} - ${hours?.wednesday?.closeTime || 'NA'}`}
            </Type14Regular2B>
          </div>
        </div>
        <div className={classes.day}>
          <div className={classes.dayHeader}>
            <Type14SemiBold2B>Thursday</Type14SemiBold2B>
          </div>
          <div className={classes.dayHours}>
            <Type14Regular2B>
              {`${hours?.thursday?.openTime || 'NA'} - ${hours?.thursday?.closeTime || 'NA'}`}
            </Type14Regular2B>
          </div>
        </div>
        <div className={classes.day}>
          <div className={classes.dayHeader}>
            <Type14SemiBold2B>Friday</Type14SemiBold2B>
          </div>
          <div className={classes.dayHours}>
            <Type14Regular2B>
              {`${hours?.friday?.openTime || 'NA'} - ${hours?.friday?.closeTime || 'NA'}`}
            </Type14Regular2B>
          </div>
        </div>
        <div className={classes.day}>
          <div className={classes.dayHeader}>
            <Type14SemiBold2B>Saturday</Type14SemiBold2B>
          </div>
          <div className={classes.dayHours}>
            <Type14Regular2B>
              {`${hours?.saturday?.openTime || 'NA'} - ${hours?.saturday?.closeTime || 'NA'}`}
            </Type14Regular2B>
          </div>
        </div>
        <div className={classes.day}>
          <div className={classes.dayHeader}>
            <Type14SemiBold2B>Sunday</Type14SemiBold2B>
          </div>
          <div className={classes.dayHours}>
            <Type14Regular2B>
              {`${hours?.sunday?.openTime || 'NA'} - ${hours?.sunday?.closeTime || 'NA'}`}
            </Type14Regular2B>
          </div>
        </div>
      </div>
    );
  }
};

export default DeliveryHoursTab;
