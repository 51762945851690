import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Paper, Button } from '@material-ui/core';
import placeIcon from '../../../assets/Place-icon.png';
import phone from '../../../assets/phone.png';
import document from '../../../assets/document.png';
import { ShoppingBagIcon } from '../../../components';
import { IListingMemberShop } from '../../../types/types';
import { PhoneLink, AddressLink } from '../../../components';
import { getValidUrl } from '../../../utilities/javascripts';
import { baseImageUrl } from '../../index';
import { internalNavButtonGA } from '../../../utilities/googleAnalytics';
import { PurpleFlower2 } from '../../../assets/svg-files/PurpleFlower2';
import GALink from '../../../components/links/GALink';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',
    width: '100%',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.09)',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04);',
      boxShadow:
        '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '180px',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
  },
  consumerImage: {
    height: '180px',
    width: '180px',
  },
  imageWrapper: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      height: '180px',
      width: '180px',
      display: 'block',
      alignSelf: 'center',
      textAlign: 'center',
      margin: '0 0 0 20px',
    },
  },
  mobileImageWrapper: {
    height: '180px',
    width: '180px',
    display: 'block',
    alignSelf: 'center',
    textAlign: 'center',
    margin: '0 0 20px 0',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  row: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  shopNameRow: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '12px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '15px',
    },
  },
  shopName: {
    maxWidth: 'calc(100% - 62px)',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0',
    lineHeight: 1,
  },
  openIndicator: {
    maxWidth: '62px',
    margin: '0 10px',
  },
  openText: {
    color: '#000000',
    padding: '3px 10px',
    font: '500 12px/18px Poppins',
  },
  sponsoredRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '12px',
    [theme.breakpoints.up('sm')]: {
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  addPhoneRows: {
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      minHeight: '24px',
      margin: '0 0 21px 0',
    },
  },
  infoRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '40px',
    margin: '0 0 6px 0',
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      margin: '0',
    },
  },
  sponsoredWrapper: {
    backgroundColor: theme.palette.secondary.main,
    width: '90px',
    height: '25px',
    borderRadius: '18px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 20px 0 0',
  },
  sponsoredTextWrap: {
    width: '85px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'left',
    alignItems: 'center',
  },
  sponsoredText: {
    font: '500 12px/18px Poppins',
    color: '#ffffff',
  },
  noLinkStyling: {
    textDecoration: 'none',
    width: '100%',
  },
  littleFlower: {
    width: '14.14px',
    height: '16.5px',
    color: '#ffffff',
  },
  button: {
    font: '500 15px/23px Poppins',
    textTransform: 'none',
    borderRadius: '5px',
  },
  buttonWrapper: {
    width: '100%',
    marginBottom: '12px',
    [theme.breakpoints.up('lg')]: {
      width: '149px',
    },
  },
  viewButtonWrapper: {
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      marginRight: '10px',
    },
  },
  locationIcon: {
    width: '14px',
    height: '19.88px',
    margin: '0 25px 0 13px',
    [theme.breakpoints.up('md')]: {
      margin: '0 8px 0 0',
    },
  },
  phoneIcon: {
    margin: '0 20px 0 8px',
    [theme.breakpoints.up('md')]: {
      margin: '0 5px 0 0',
    },
  },
  margin: {
    marginRight: '25px',
  },
}));

export interface FloristCardProps {
  listing: IListingMemberShop;
}

const FloristCard: React.FC<FloristCardProps> = ({ listing }) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    shopCode,
    shopName,
    shopWebsite,
    shopCity,
    shopState,
    shopAddress1,
    shopAddress2,
    shopZip,
    shopPhone,
    isOpen,
    shopLogo,
    listingAdSize,
    listingBid,
	listingCitySponsorAd,
	listingCityPreferredAd,
  } = listing;

  const handleViewOnMapBtn = (e: any) => {
    internalNavButtonGA(e.currentTarget.id);
  };

  const params = new URLSearchParams(location.search);
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

  return (
    <div
      className={classes.card}
      itemScope
      itemType="http://schema.org/LocalBusiness"
      itemRef={`_telephone${shopCode} _address${shopCode}`}
    >
      <div className={classes.content}>
        {/* Shop Logo Mobile and 1024 Only */}
        {!!shopLogo ? (
          <div className={classes.mobileImageWrapper}>
            <img
              className={classes.consumerImage}
              src={baseImageUrl.concat(shopLogo)}
              alt={shopName}
              onLoad={() => setImageLoaded(true)}
              style={!imageLoaded ? { display: 'none' } : {}}
            />
          </div>
        ) : null}

        {/* Sponsored Row */}
        <div className={classes.sponsoredRow}>
          {!!listingCitySponsorAd ? (
            <Paper elevation={0} className={classes.sponsoredWrapper}>
              <div className={classes.sponsoredTextWrap}><Typography>&nbsp;</Typography>
                <PurpleFlower2 className={classes.littleFlower} />
                <Typography className={classes.sponsoredText}>&nbsp;&nbsp;Featured</Typography>
              </div>
            </Paper>
          ) : !!listingCityPreferredAd ? (
			<Paper elevation={0} className={classes.sponsoredWrapper}>
              <div className={classes.sponsoredTextWrap}><Typography>&nbsp;</Typography>
                <Typography className={classes.sponsoredText}>&nbsp;&nbsp;&nbsp;&nbsp;Preferred</Typography>
              </div>
            </Paper>
		  ) : null}
        </div>

        {/* Shop Name Row, and Open Indicator Desktop Only  */}
        <div className={classes.shopNameRow}>
          <Typography className={classes.shopName} variant="h6">
            {shopName}
          </Typography>
          <div className={classes.openIndicator}>
            <OpenIndicator className="open-indicator" isOpen={isOpen}>
              <Typography className={classes.openText}>{isOpen ? 'Open' : 'Closed'}</Typography>
            </OpenIndicator>
          </div>
        </div>

        {/* Address and Phone Rows */}
        <div className={clsx(classes.row, classes.addPhoneRows)}>
          <div className={clsx(classes.infoRow, classes.margin)}>
            <img className={classes.locationIcon} src={placeIcon} alt="Location Marker Icon" />
            <div>
              <AddressLink
                id={`_address${shopCode}`}
                address1={shopAddress1}
                address2={shopAddress2}
                city={shopCity}
                state={shopState}
                zip={shopZip}
              />
            </div>
          </div>
          <div className={classes.infoRow}>
            <img className={classes.phoneIcon} src={phone} alt="Phone Icon" />
            <PhoneLink id={`_telephone${shopCode}`} phone={shopPhone} />
          </div>
        </div>

        {/* Button Rows */}
        <div className={classes.row}>
          <div className={clsx(classes.buttonWrapper, classes.viewButtonWrapper)}>
            <Button
              id="results-view-details"
              component={RouterLink}
              className={classes.button}
              fullWidth
              to={{
                pathname: `/our-florists/${shopCode}`,
                search: params.toString(),
                state: listing,
              }}
              onClick={handleViewOnMapBtn}
              color="default"
              variant="contained"
              disableElevation
              startIcon={<img src={document} alt="Document icon" />}
            >
              View Details
            </Button>
          </div>
          {!!shopWebsite ? (
            <div className={classes.buttonWrapper}>
              <GALink
                className={classes.noLinkStyling}
                eventLabel={`Florists Results Website Click; ShopCode: ${shopCode}, Website: ${shopWebsite}`}
                to={getValidUrl(shopWebsite)}
              >
                <Button
                  className={classes.button}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  startIcon={<ShoppingBagIcon color="#ffffff" />}
                >
                  Shop Now
                </Button>
              </GALink>
            </div>
          ) : null}
        </div>
      </div>

      {/* Shop Logo Desktop Only */}
      {!!shopLogo ? (
        <div className={classes.imageWrapper}>
          <img
            itemProp="image"
            className={classes.consumerImage}
            src={baseImageUrl.concat(shopLogo)}
            alt={shopName}
            onLoad={() => setImageLoaded(true)}
            style={!imageLoaded ? { display: 'none' } : {}}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FloristCard;

const BaseWrapper: React.FC<BaseWrapperProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
);

type BaseWrapperProps = {
  className: string;
  children: React.ReactNode;
  isOpen: boolean;
};

const OpenIndicator = styled(BaseWrapper)`
  background: ${(props) =>
    props.isOpen
      ? `rgba(125, 200, 131, .25) 0% 0% no-repeat padding-box`
      : `rgba(233, 2, 2, .12) 0% 0% no-repeat padding-box`};
  border-radius: 19px;
`;
