import * as React from 'react';
import { Typography } from '@material-ui/core';

const NoResults: React.FC = (): React.ReactElement => (
  <div style={{ margin: '0 25px' }}>
    <Typography variant="body1" color="textSecondary" style={{ fontSize: '18px' }}>
      We curate the most popular florists in your area by… lorem ipsum dolor sit amet, consetetur
      sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
    </Typography>
  </div>
);

export default NoResults;
