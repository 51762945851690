import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { IAdvertisement } from '../../types/types';
import { getValidUrl } from '../../utilities/javascripts';
import GALink from '../../components/links/GALink';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  imageCell: {
    padding: theme.spacing(2),
    width: '100%',
  },
  banners: {
    objectFit: 'scale-down',
    maxWidth: '100%',
  },
  images: {
    width: '100%',
    height: 'auto',
  },
}));

export interface AdSpaceProps {
  rectangleAdvertisements: Array<IAdvertisement> | null;
  squareAdvertisements: Array<IAdvertisement> | null;
}

const AdSpace: React.FC<AdSpaceProps> = ({
  rectangleAdvertisements: banners,
  squareAdvertisements: squares,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1} justify="center">
        {!!banners &&
          banners.map((b) => {
            return (
              <Grid item xs={12} key={b.fileName} className={classes.imageCell}>
                <GALink eventLabel={`AdSpace Click: ${b.website}`} to={getValidUrl(b.website)}>
                  <img
                    className={classes.banners}
                    src={b.fileName}
                    alt={!!b.website ? b.website : 'stock floral image'}
                  />
                </GALink>
              </Grid>
            );
          })}
      </Grid>
      <Grid container spacing={1} justify="space-evenly">
        {!!squares &&
          squares.map((s) => {
            return (
              <Grid item xs={6} md={3} key={s.fileName} className={classes.imageCell}>
                <GALink eventLabel={`AdSpace Click: ${s.website}`} to={getValidUrl(s.website)}>
                  <img
                    className={classes.images}
                    src={s.fileName}
                    alt={!!s.website ? s.website : 'stock floral image'}
                  />
                </GALink>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default AdSpace;
