import * as React from 'react';
import styled from 'styled-components';
import heroImg from '../../assets/heroImg.jpg';
import GetDelivery from './GetDelivery';
import { BaseWrapperProps } from '../../types/types';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { internalNavButtonGA } from '../../utilities/googleAnalytics';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: `min(max(12px, 4vw), 18px)`,
    lineHeight: '27px',
    fontWeight: 500,
    color: '#ffffff',
    [theme.breakpoints.only('xs')]: {
      margin: '0',
    },
  },
  expand: {
    margin: '0 auto',
    [theme.breakpoints.only('xs')]: {
      padding: '0',
    },
  },
  heroMore: {
    height: '18.5vh',
    [theme.breakpoints.only('xs')]: {
      height: '12vh',
      padding: '5px 0',
    },
  },
  deliveryWrapper: {
    height: 'calc(100% - 18.5vh)',
    [theme.breakpoints.only('xs')]: {
      height: 'calc(100% - 12vh)',
    },
  },
}));

const HeroImage: React.FC = () => {
  const classes = useStyles();

  const smoothScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    let offset = () => 0;
    if (!e || !e.currentTarget) {
      return;
    }
    const link = e.currentTarget.getAttribute('href');
    if (!link) {
      return;
    }
    const id = link.slice(1);
    const $anchor = document.getElementById(id);
    if (!$anchor) {
      return;
    }
    const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
    const $root = document.getElementById('root');
    $root?.scroll({
      top: offsetTop - offset(),
      behavior: 'smooth',
    });
    internalNavButtonGA(e.currentTarget.id);
  };
  return (
    <HeroDiv className="hero-div">
      <HeroLayer>
        <DeliveryWrapper className={classes.deliveryWrapper}>
          <GetDelivery />
        </DeliveryWrapper>
        <HeroMore className={classes.heroMore}>
          <p className={classes.text}>Featured florists in your area</p>
          <IconButton
            id="scroll-to-popular-section"
            href="#PopularSection"
            className={classes.expand}
            onClick={smoothScroll}
          >
            <ExpandMoreIcon style={{ color: 'white' }} fontSize="large" />
          </IconButton>
        </HeroMore>
      </HeroLayer>
    </HeroDiv>
  );
};

export default HeroImage;

const BaseDiv: React.FC<BaseWrapperProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
);

const HeroDiv = styled(BaseDiv)`
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${heroImg}) no-repeat center center,
    transparent linear-gradient(180deg, #07152c00 0%, #07152c 100%) 0% 0% no-repeat;
  background-blend-mode: exclusion;
  background-size: cover;
  height: calc(100vh - 65px);
  min-height: 560px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  /* DESKTOPS */
  @media only screen and (min-width: 768px) {
    height: calc(100vh - 80px);
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(${heroImg}) no-repeat center center;
    background-blend-mode: exclusion;
    background-size: cover;
  }
`;

const HeroLayer = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    background: rgba(7, 21, 44, 0.6) 0% 0% no-repeat padding-box;
  }
`;

const HeroMore = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0px;
  @media only screen and (min-width: 768px) {
    background: transparent linear-gradient(180deg, #07152c00 0%, #07152c 100%) 0% 0% no-repeat;
  }
`;

const DeliveryWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
