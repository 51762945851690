import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PurpleFlower2 = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H26.86V26.86H0Z" fill="none" />
      <path
        d="M13.072,24.5A10.073,10.073,0,0,0,23.145,14.43,10.073,10.073,0,0,0,13.072,24.5ZM5.91,11.352A2.8,2.8,0,0,0,10.3,13.658l-.022.213a2.8,2.8,0,1,0,5.6,0l-.022-.213a2.758,2.758,0,0,0,1.589.492,2.791,2.791,0,0,0,1.2-5.316,2.794,2.794,0,1,0-2.787-4.824L15.87,3.8a2.8,2.8,0,0,0-5.6,0l.022.213a2.758,2.758,0,0,0-1.589-.492,2.791,2.791,0,0,0-1.2,5.316,2.788,2.788,0,0,0-1.6,2.518Zm7.163-5.316a2.8,2.8,0,1,1-2.8,2.8A2.8,2.8,0,0,1,13.072,6.036ZM3,14.43A10.073,10.073,0,0,0,13.072,24.5,10.073,10.073,0,0,0,3,14.43Z"
        transform="translate(0.357 0.119)"
      />
    </SvgIcon>
  );
};
