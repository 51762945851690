import React from 'react';
import { IconButton } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useHistory } from 'react-router-dom';
import { internalNavButtonGA } from '../../utilities/googleAnalytics';

const NavigateBack = () => {
  const history = useHistory();

  const handleBackButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    history.goBack();
    internalNavButtonGA(e.currentTarget.id);
  };

  return (
    <IconButton onClick={handleBackButtonClick}>
      <NavigateBeforeIcon color="inherit" />
    </IconButton>
  );
};

export default NavigateBack;
