import { Hidden } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import FindFlorist, { SearchType } from '../../components/forms/FindFlorist';

const MobileSearch: React.FC = () => {
  return (
    <Hidden mdUp>
      <SearchWrapper>
        <FindFlorist type={SearchType.Mobile} />
      </SearchWrapper>
    </Hidden>
  );
};

export default MobileSearch;

const SearchWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 20px 25px 0px 25px;
`;
