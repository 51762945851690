import * as React from 'react';
import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, Paper, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import clsx from 'clsx';
import { IListingMemberShop, ILocInfo, IRouterState } from '../../../types/types';
import { titleCaser, streetAddress, getValidUrl } from '../../../utilities/javascripts';
import { internalNavButtonGA } from '../../../utilities/googleAnalytics';
import { ShoppingBagIcon } from '../../../components/index';
import { PurpleFlower2 } from '../../../assets/svg-files/PurpleFlower2';
import GALink from '../../../components/links/GALink';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: '325px',
    backgroundColor: 'none',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto auto auto',
    gridTemplateAreas: `
            'sponsored'
            'shop-name'
            'info'
            'buttons'
          `,
    gridRowGap: '6px',
  },
  premium: {
    color: '#5A3DEF',
    '&:hover': {
      borderWidth: '2px;',
    },
  },
  noLinkStyling: {
    textDecoration: 'none',
  },
  sponsored: {
    backgroundColor: theme.palette.secondary.main,
    width: '90px',
    height: '25px',
    borderRadius: '18px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noSponsor: {
    height: '25px',
  },
  sponsoredTextWrap: {
    width: '85px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'left',
    alignItems: 'center',
  },
  sponsoredText: {
    font: '500 12px/18px Poppins',
    color: '#ffffff',
  },
  littleFlower: {
    width: '14.14px',
    height: '16.5px',
    color: '#ffffff',
  },
  button: {
    minWidth: '145px',
    maxWidth: '350px',
    height: '44px',
    font: '500 15px/18px Poppins',
    borderWidth: '2px',
    borderRadius: '5px',
    textTransform: 'none',
    padding: '5px 10px',
  },
  viewBtn: {
    '&:hover': {
      backgroundColor: '#5A3DEF',
      color: '#ffffff',
    },
  },
  gridShopBtn: {
    gridArea: 'shopBtn',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      justifySelf: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '300px',
      justifySelf: 'center',
    },
  },
  gridViewBtn: {
    gridArea: 'viewBtn',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      justifySelf: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '300px',
      justifySelf: 'center',
    },
  },
}));

export interface PopFloristCardProps {
  loc: ILocInfo;
  idx: number;
  listing: IListingMemberShop;
}

const PopFloristCard: React.FC<PopFloristCardProps> = ({
  idx,
  loc,
  listing,
  listing: {
    shopCode,
    shopName,
    shopAddress1,
    shopAddress2,
    shopCity,
    shopState,
    shopZip,
    shopPhone,
    shopWebsite,
    listingAdSize,
    listingBid,
	listingCitySponsorAd,
	listingCityPreferredAd,
  },
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const handleViewOnMapBtn = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    internalNavButtonGA(event.currentTarget.id);
  };

  const [isPromo, setIsPromo] = React.useState<boolean>(false);
  const [isPromo2, setIsPromo2] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!!listingCitySponsorAd) setIsPromo(true);
	if (!!listingCityPreferredAd) setIsPromo2(true);
  }, [listingAdSize, listingBid]);

  const viewText = isMobile ? 'View Details' : isPromo ? `View on Map` : 'View on Map »';

  const buildLocalParams = () => {
    const searchParams = new URLSearchParams();
    const date = new Date();

    searchParams.append('city', loc.city.toUpperCase());
    searchParams.append('state', loc.state_long.toUpperCase());
    searchParams.append('sc', loc.state_short);
    searchParams.append('zip', loc.postal_code);
    searchParams.append('date', format(date, 'yyyy-MM-dd'));

    return searchParams;
  };

  const params = buildLocalParams();

  const routerState: IRouterState = {
    listingState: listing,
    headerState: {
      cityName: undefined,
      facilityName: undefined,
      resultsText: 'Your Selected Florist',
    },
  };

  return (
    <React.Fragment key={shopCode}>
      <div className={classes.card}>
        <GridSponsored>
          {isPromo ? (
            <Paper elevation={0} className={classes.sponsored}>
              <div className={classes.sponsoredTextWrap}><Typography>&nbsp;</Typography>
                <PurpleFlower2 className={classes.littleFlower} />
                <Typography className={classes.sponsoredText}>&nbsp;&nbsp;Featured</Typography>
              </div>
            </Paper>
          ) : isPromo2 ? (
			<Paper elevation={0} className={classes.sponsored}>
              <div className={classes.sponsoredTextWrap}><Typography>&nbsp;</Typography>
                <Typography className={classes.sponsoredText}>&nbsp;&nbsp;&nbsp;&nbsp;Preferred</Typography>
              </div>
            </Paper>
		  ) :(
            <div className={classes.noSponsor}></div>
          )}
        </GridSponsored>
        <GridShopName>
          <ShopName>{shopName}</ShopName>
        </GridShopName>
        <GridShopInfo>
          <ShopInfo>
            <Line>{streetAddress(shopAddress1, shopAddress2)}</Line>
            <Line>
              {shopCity ? titleCaser(shopCity) : ''}, {shopState} {shopZip}
            </Line>
            <Line>{shopPhone}</Line>
          </ShopInfo>
        </GridShopInfo>
        <GridButtonArea>
          {!!shopWebsite ? (
            <div className={classes.gridShopBtn}>
              <GALink
                className={classes.noLinkStyling}
                eventLabel={`Popular Florist's Website; ShopCode: ${shopCode}, Website: ${shopWebsite}`}
                to={getValidUrl(shopWebsite)}
              >
                <Button
                  className={classes.button}
                  fullWidth
                  disableElevation
                  color="primary"
                  variant="contained"
                  startIcon={<ShoppingBagIcon color="#ffffff" />}
                >
                  Shop Now
                </Button>
              </GALink>
            </div>
          ) : null}
          <div className={classes.gridViewBtn}>
            <Button
              className={isPromo ? clsx(classes.button, classes.viewBtn) : classes.button}
              id="home-page-view-map"
              fullWidth
              disableElevation
              color={isPromo ? 'secondary' : 'default'}
              variant={isPromo ? 'outlined' : 'contained'}
              startIcon={isPromo ? <PurpleFlower2 /> : null}
              onClick={handleViewOnMapBtn}
              component={RouterLink}
              to={
                isMobile
                  ? {
                      pathname: `/our-florists/${shopCode}`,
                      search: params.toString(),
                      state: listing,
                    }
                  : {
                      pathname: '/our-florists',
                      search: params.toString(),
                      state: routerState,
                    }
              }
            >
              {viewText}
            </Button>
          </div>
        </GridButtonArea>
      </div>
    </React.Fragment>
  );
};

export default PopFloristCard;

const GridArea = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: 960px) {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
`;

const GridSponsored = styled(GridArea)`
  grid-row: sponsored;
`;

const GridShopName = styled(GridArea)`
  grid-row: shop-name;
`;

const ShopName = styled.h2`
  display: flex;
  flex-flow: row wrap;
  font-size: 26px;
  font-weight: 600;
  margin: 0;
`;

const GridShopInfo = styled(GridArea)`
  grid-row: info;
`;

const ShopInfo = styled.div`
  font: 18px/26px Poppins;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #7c7676;
  @media only screen and (min-width: 960px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Line = styled.p`
  margin: 0;
  line-height: 26px;
`;

const GridButtonArea = styled.div`
  grid-area: buttons;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  grid-template-areas:
    'viewBtn'
    'shopBtn';
  width: 100%;
  @media only screen and (min-width: 960px) {
    grid-template-columns: repeat(2, 128px);
    grid-template-rows: auto;
    grid-template-areas: 'viewBtn shopBtn';
  }
`;
