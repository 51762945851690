import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/bloomnetLogoSmall.png';
import { makeStyles } from '@material-ui/core/styles';
import { internalNavButtonGA } from '../../utilities/googleAnalytics';

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  image: {
    borderRadius: '4px',
    objectFit: 'scale-down',
    maxWidth: '99%',
    maxHeight: '90px',
  },
}));

const LogoLink = () => {
  const classes = useStyles();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    internalNavButtonGA(e.currentTarget.id);
  };
  return (
    <RouterLink id="logo-link" className={classes.wrapper} to="/home" onClick={handleClick}>
      <img src={logo} alt="Bloomnet Logo" className={classes.image}/>
    </RouterLink>
  );
};

export default LogoLink;
