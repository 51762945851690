import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import {
  FormControl,
  TextField,
  FormGroup,
  Divider,
  Grid,
  Button,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { AxiosResponse } from 'axios';
import {
  IFacility,
  IErrorMessage,
  isErrorMessage,
  ICity,
  IState,
  ILocation,
  ILocationType,
  IHeaderInfo,
  IRouterState,
} from '../../types/types';
import api from '../../utilities/api';
import { ComboBox } from '../index';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Type18SemiBoldBlack } from '../font';
import { searchButtonGA } from '../../utilities/googleAnalytics';
import axios from 'axios';

export enum SearchType {
  Default = 0,
  Compact = 1,
  Mobile = 2,
}

export const locationTypes: Array<ILocationType> = [
  { id: 'null', name: 'Residential' },
  { id: '4', name: 'Funeral Homes' },
  { id: '8', name: 'Nursing Homes' },
  { id: '9', name: 'Hospitals' },
];

const useStyles = makeStyles((theme) => ({
  // Common
  divider: {
    borderColor: '#ebebeb',
    height: '48px',
    margin: 0,
  },
  controlColor: {
    '& .MuiInputBase-root': {
      backgroundColor: '#FFFFFF',
    },
  },
  searchButton: {
    height: '50px',
    width: '50px',
    borderRadius: '0 5px 5px 0',
    marginTop: '-2px',
    fontSize: '14px',
  },

  // Compact
  compactSearch: {
    borderTop: '2px solid #ebebeb',
    borderBottom: '2px solid #ebebeb',
    borderLeft: '2px solid #ebebeb',
    borderRight: 'none',
    borderRadius: '5px',
    height: '50px',
    flexWrap: 'nowrap',
    width: 'auto',
    minWidth: 800,
    [theme.breakpoints.up('lg')]: {
      minWidth: 1100,
    },
  },
  compactZip: {
    width: '80px',
    minWidth: '80px',
  },
  compactState: {
	flex: 1,
    width: '240px',
    minWidth: '120px',
  },
  compactDeliveryDate: {
    width: '161px',
    minWidth: '161px',
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: '0px',
    },
  },
  compactControl: {
    flex: 1,
    minWidth: '120px',
  },
  compactLocation: {
	flex: 1,
	width: '170px',
    minWidth: '130px',
  },

  // Mobile
  mobileGrid: {
    padding: '25px',
    width: '100%',
    margin: '0',
  },
  mobileSearchGrid: {
    '@media (max-width:480px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  mobileSearch: {
    borderRadius: '5px',
    textTransform: 'capitalize',
    '& .MuiSvgIcon-root': {
      marginRight: '10px',
    },
    '@media (max-width:480px)': {
      width: '100%',
    },
  },
  mobileFilter: {
    textTransform: 'none',
    paddingRight: '12px',
    paddingLeft: '14px',
    borderRadius: '5px',
    width: '100%',
    '@media (min-width:480px)': {
      width: '112px',
    },
  },
  searchDefaultView: {
    height: '56px',
    borderRadius: '5px',
  },

  // Mobile Popup
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: 'center',
  },
  clear: {
    textDecoration: 'underline',
    fontSize: '14px',
    lineHeight: '26px',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  searchMobile: {
    height: '60px',
    borderRadius: '5px',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface FindFloristProps {
  type: SearchType;
}

const FindFlorist: React.FC<FindFloristProps> = ({ type }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const history = useHistory();
  const firstRender = React.useRef(true);

  // URL Parameters
  const params = new URLSearchParams(useLocation().search);
  const cityParam = params.get('city');
  const stateParam = params.get('state');
  const stateCodeParam = params.get('sc') || '';
  const zipParam = params.get('zip') || '';
  const countryParam = params.get('country') || 'USA';
  const locationTypeParam = params.get('locationType') || locationTypes[0].id;
  const facilityParam = params.get('facility') || '';
  const dateParam = params.get('date') || '';

  // Default Values
  const defaultDate = dateParam !== '' ? parseISO(dateParam) : new Date();
  const defaultCity = !!cityParam ? ({ cityName: cityParam } as ICity) : null;
  const defaultState = !!stateParam
    ? ({ stateName: stateParam, stateShortName: stateCodeParam } as IState)
    : null;
  const defaultFacility = !!facilityParam
    ? ({ facilityID: facilityParam, facilityName: '' } as IFacility)
    : null;
  const defaultLocationType =
    locationTypes.find((type) => type.id === locationTypeParam) || locationTypes[0];

  // Options
  const [cities, setCities] = React.useState<Array<ICity>>();
  const [states, setStates] = React.useState<Array<IState>>();
  const [facilities, setFacilities] = React.useState<Array<IFacility>>();

  // Search Fields
  const [city, setCity] = React.useState<ICity | null>(defaultCity);
  const [state, setState] = React.useState<IState | null>(defaultState);
  const [zip, setZip] = React.useState<string>(zipParam);
  const [country] = React.useState<string>(countryParam);
  const [locationType, setLocationType] = React.useState<ILocationType | null>(defaultLocationType);
  const [facility, setFacility] = React.useState<IFacility | null>(defaultFacility);
  const [date, setDate] = React.useState<Date | null>(defaultDate);

  // Required Errors
  const [errorCity, setErrorCity] = React.useState<boolean>(false);
  const [errorState, setErrorState] = React.useState<boolean>(false);
  const [errorLocationType, setErrorLocationType] = React.useState<boolean>(false);
  const [errorFacility, setErrorFacility] = React.useState<boolean>(false);
  const [errorDate, setErrorDate] = React.useState<boolean>(false);

  // Helpers
  const [open, setOpen] = React.useState(false);
  const [facilityVisible, setFacilityVisible] = React.useState<boolean>(
    !!city && !!state && !!locationType && locationType.id !== 'null',
  );

  /**
   * Zip changed so get city and state
   */
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    // Dont run on first render
    if (firstRender.current) {
      return;
    }

    // Make sure Zip is valid
    if (zip.length < 5) {
      return;
    }

    api.getCityAndState(zip).then((response) => {
      let { status, data } = response;
      if (status === 200 && !!data && data.length > 0) {
        const location = data[0];
        if (isErrorMessage(location)) {
          // TODO - Handle / Log error.
        } else {
          data = data as Array<ILocation>;
          const location = data[0];
          setCity(location); // Needs set before State so when state changes it can look for city
          setState(location);
        }
      } else {
        // TODO - Handle No city state found for Zip
      }
    });

    return () => source.cancel('Cancelling API call');
  }, [zip]);

  /**
   * State changed so get list of cities
   */
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    // Dont run on first render
    if (firstRender.current) {
      return;
    }

    if (state) {
      api.getCities(state.stateName).then((response) => {
        let { status, data } = response;
        if (status === 200 && !!data && data.length > 0) {
          const first = data[0];
          if (isErrorMessage(first)) {
            // TODO - Handle / Log error.
          } else {
            data = data as Array<ICity>;
            setCities(data);
          }
        } else {
          // TODO - Handle / Log error.
          setCities([]);
        }
      });
    } else {
      setCities([]);
      setCity(null);
    }

    return () => source.cancel('Cancelling API call');
  }, [state]);

  /**
   * States changed so select state
   */
  React.useEffect(() => {
    // Dont run on first render
    if (firstRender.current) {
      return;
    }

    if (!!state && !!states) {
      const found = states.find((s) => s.stateShortName === state.stateShortName);
      if (found) {
        setState(found);
      }
    }
  }, [state, states]);

  /**
   * Cities change so select city
   */
  React.useEffect(() => {
    // Dont run on first render
    if (firstRender.current) {
      return;
    }

    if (!!city && !!cities) {
      const foundCity = cities.find((c) => c.cityName === city.cityName);
      if (foundCity) {
        setCity(foundCity);
      }
    }
  }, [city, cities]);

  /**
   * Location or Location Type Changed - Update Facilities Dropdown
   */
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    // Dont run on first render
    if (firstRender.current) {
      return;
    }

    if (city && state && locationType && locationType.id !== 'null') {
      const facilityTypeId = Number(locationType.id);
      api
        .getFacilitiesByCityAndState(city.cityName, state.stateName, facilityTypeId)
        .then((results: AxiosResponse<IFacility[] | IErrorMessage[]>) => {
          if (results.status === 200 && results.data.length > 0) {
            if (isErrorMessage(results.data[0])) {
              setFacilities([]);
              setFacilityVisible(true);
            } else {
              const data = results.data as Array<IFacility>;
              setFacilities(data);
              setFacilityVisible(true);
            }
          }
        })
        .catch(() => {
          setFacilityVisible(false);
        });
    } else {
      setFacilityVisible(false);
    }

    return () => source.cancel('Cancelling API call');
  }, [locationType, city, state]);

  /**
   * Location or Location Type Changed - Update Facilities Dropdown
   */
  React.useEffect(() => {
    // Dont run on first render
    if (firstRender.current) {
      return;
    }

    if (!!facility && !!facilities) {
      const found = facilities.find((f) => f.facilityID === facility.facilityID);
      if (found) {
        setFacility(found);
      } else {
        setFacility(null);
      }
    }
  }, [facilities, facility]);

  /**
   * Populate States
   */
  React.useEffect(() => {
    const source = axios.CancelToken.source();
    api.getStates(country).then((response) => {
      let { status, data } = response;
      if (status === 200) {
        if (isErrorMessage(data)) {
        } else {
          data = data as Array<IState>;
          setStates(data);
        }
      }
    });

    return () => source.cancel('Cancelling API call');
  }, [country]);

  /**
   * Populate States
   */
  React.useEffect(() => {
    // Dont let any of the previous useEffect run till after first render
    firstRender.current = false;
  }, []);

  /**
   * Zip Changed
   * @param e
   */
  const handleZipChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setZip(value);
  };

  /**
   * Delivery Date Changed
   * @param value
   */
  const handleDateChange = (value: Date | null) => {
    setDate(value);
  };

  /**
   * Search clicked
   *
   */
  const searchClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    let hasError = false;
    if (city) {
      setErrorCity(false);
      params.set('city', city.cityName);
    } else {
      hasError = true;
      setErrorCity(true);
      if (params.has('city')) {
        params.delete('city');
      }
    }

    if (state) {
      setErrorState(false);
      params.set('state', state.stateName);
      params.set('sc', state.stateShortName);
    } else {
      hasError = true;
      setErrorState(true);
      if (params.has('state')) {
        params.delete('state');
      }
      if (params.has('sc')) {
        params.delete('sc');
      }
    }

    if (zip) {
      params.set('zip', zip);
    } else {
      if (params.has('zip')) {
        params.delete('zip');
      }
    }

    if (locationType) {
      setErrorLocationType(false);
      params.set('locationType', locationType.id);
    } else {
      hasError = true;
      setErrorLocationType(true);
      if (params.has('locationType')) {
        params.delete('locationType');
      }
    }

    if (facility) {
      setErrorFacility(false);
      params.set('facility', facility.facilityID);
    } else {
      if (locationType?.id !== 'null') {
        hasError = true;
        setErrorFacility(true);
      }
      if (params.has('facility')) {
        params.delete('facility');
      }
    }

    if (date) {
      setErrorDate(false);
      params.set('date', format(date, 'yyyy-MM-dd'));
    } else {
      hasError = true;
      setErrorDate(true);
      if (params.has('date')) {
        params.delete('date');
      }
    }

    if (!hasError) {
      setOpen(false);
      const searchParams = {
        city: city?.cityName,
        state: state,
        zip: zip,
        country: country,
        location: locationType,
        facility: facility?.facilityID,
        date: date,
      };

      const headerInfo: IHeaderInfo = {
        cityName: city?.cityName,
        facilityName: facility?.facilityName,
      };

      const routerState: IRouterState = {
        listingState: undefined,
        headerState: headerInfo,
      };

      searchButtonGA(e.currentTarget.id, JSON.stringify(searchParams));
      history.push({
        pathname: '/our-florists',
        search: params.toString(),
        state: routerState,
      });
    }
  };

  /**
   * Open Search Modal
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Close Search Modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Clear Fields
   */
  const handleClear = () => {
    setZip('');
    setCity(null);
    setState(null);
    setDate(new Date());
    setLocationType(locationTypes[0]);
  };

  /**
   * Search when users click enter
   * @param event
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      searchClick(event);
    }
  };

  const CityComboBox = (
    <ComboBox
      id="city"
      options={cities || []}
      optionId="cityName"
      optionLabel="cityName"
      inputLabel={isDesktop ? 'City' : undefined}
      placeholder={isDesktop ? undefined : 'City'}
      value={city}
      setValue={setCity}
      required={true}
      error={errorCity}
      className={classes.controlColor}
      size={type === SearchType.Compact ? 'small' : 'medium'}
      disableUnderline={type === SearchType.Compact}
      variant={type === SearchType.Compact ? 'filled' : 'outlined'}
      onKeyPress={handleKeyPress}
    />
  );
  const StateComboBox = (
    <ComboBox
      id="state"
      options={states || []}
      optionId={type === SearchType.Compact ? 'stateName' : 'stateName'}
      optionLabel={type === SearchType.Compact ? 'stateName' : 'stateName'}
      inputLabel={isDesktop ? 'State' : undefined}
      placeholder={isDesktop ? undefined : 'State'}
      value={state}
      setValue={setState}
      required={true}
      error={errorState}
      className={classes.controlColor}
      size={type === SearchType.Compact ? 'small' : 'medium'}
      disableUnderline={type === SearchType.Compact}
      variant={type === SearchType.Compact ? 'filled' : 'outlined'}
      onKeyPress={handleKeyPress}
    />
  );
  const LocationTypeComboBox = (
    <ComboBox
      id="location-type"
      options={locationTypes}
      optionId="id"
      optionLabel="name"
      inputLabel={isDesktop ? 'Location Type' : undefined}
      placeholder={isDesktop ? undefined : 'Location Type'}
      value={locationType}
      setValue={setLocationType}
      defaultValue={locationTypes[0]}
      required={true}
      error={errorLocationType}
      className={classes.controlColor}
      size={type === SearchType.Compact ? 'small' : 'medium'}
      disableUnderline={type === SearchType.Compact}
      variant={type === SearchType.Compact ? 'filled' : 'outlined'}
      onKeyPress={handleKeyPress}
    />
  );
  const FacilityTypeComboBox = (
    <ComboBox
      id="facility"
      options={facilities || []}
      optionId="facilityID"
      optionLabel="facilityName"
      inputLabel={isDesktop ? 'Facility' : undefined}
      placeholder={isDesktop ? undefined : 'Facility'}
      value={facility}
      setValue={setFacility}
      required={true}
      error={errorFacility}
      className={classes.controlColor}
      size={type === SearchType.Compact ? 'small' : 'medium'}
      disableUnderline={type === SearchType.Compact}
      variant={type === SearchType.Compact ? 'filled' : 'outlined'}
      onKeyPress={handleKeyPress}
    />
  );

  const ZIPTextField = (
    <TextField
      id="zip"
      name="zip"
      value={zip}
      label={isDesktop ? 'Zip' : undefined}
      placeholder={isDesktop ? undefined : 'Zip'}
      className={classes.controlColor}
      onChange={handleZipChange}
      fullWidth={type !== SearchType.Compact}
      size={type === SearchType.Compact ? 'small' : 'medium'}
      variant={type === SearchType.Compact ? ('filled' as any) : ('outlined' as any)}
      InputProps={{
        ...(type === SearchType.Compact ? { disableUnderline: true } : {}),
      }}
      onKeyPress={handleKeyPress}
      autoComplete="chrome-off"
    />
  );

  const datePicker = (
    <KeyboardDatePicker
      id="delivery-date"
      name="delivery-date"
      value={date}
      label={isDesktop ? 'Delivery Date' : undefined}
      placeholder={isDesktop ? undefined : 'Delivery Date'}
      required={true}
      error={errorDate}
      disablePast
      onChange={handleDateChange}
      format="MM/dd/yyyy"
      inputVariant={type === SearchType.Compact ? ('filled' as any) : ('outlined' as any)}
      size={type === SearchType.Compact ? 'small' : 'medium'}
      className={classes.controlColor}
      InputProps={{
        ...(type === SearchType.Compact ? { disableUnderline: true } : {}),
      }}
      onKeyPress={handleKeyPress}
    />
  );

  switch (type) {
    case SearchType.Compact:
      return (
        <FormGroup row={true} className={classes.compactSearch}>
          <StyledFormControl size="small" variant="filled" className={classes.compactZip}>
            {ZIPTextField}
          </StyledFormControl>
          <Divider className={classes.divider} orientation="vertical" />
          <StyledFormControl size="small" variant="filled" className={classes.compactState}>
            {StateComboBox}
          </StyledFormControl>
          <Divider className={classes.divider} orientation="vertical" />
          <StyledFormControl size="small" variant="filled" className={classes.compactControl}>
            {CityComboBox}
          </StyledFormControl>
          <Divider className={classes.divider} orientation="vertical" />
          <StyledFormControl size="small" variant="filled" className={classes.compactLocation}>
            {LocationTypeComboBox}
          </StyledFormControl>
          {facilityVisible ? (
            <>
              <Divider className={classes.divider} orientation="vertical" />
              <StyledFormControl size="small" variant="filled" className={classes.compactControl}>
                {FacilityTypeComboBox}
              </StyledFormControl>
            </>
          ) : null}
          <Divider className={classes.divider} orientation="vertical" />
          <StyledFormControl variant="filled" className={classes.compactDeliveryDate}>
            {datePicker}
          </StyledFormControl>
          <Button
            className={classes.searchButton}
            color="primary"
            variant="contained"
            disableElevation
            onClick={searchClick}
            id="find-florists-compact-view"
          >
            <SearchIcon />
          </Button>
        </FormGroup>
      );
    case SearchType.Mobile:
      const searchButtonText =
        !!city && !!state ? `${city.cityName.toLowerCase()}, ${state.stateShortName}` : 'Search...';
      return (
        <Grid container justify="space-between">
          <Grid
            container
            item
            xs={5}
            alignItems="center"
            justify="flex-end"
            direction="row"
            className={classes.mobileSearchGrid}
          >
            <Button
              disableElevation
              size="large"
              onClick={handleClickOpen}
              className={classes.mobileSearch}
            >
              <SearchIcon />
              {searchButtonText}
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={5}
            alignItems="center"
            justify="flex-start"
            direction="row"
            className={classes.mobileSearchGrid}
          >
            <Button
              fullWidth
              onClick={handleClickOpen}
              variant="contained"
              disableElevation
              size="large"
              endIcon={<ArrowDropDownIcon />}
              className={classes.mobileFilter}
            >
              Filters
            </Button>
          </Grid>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar color="transparent" className={classes.appBar} variant="outlined">
              <Toolbar>
                <IconButton edge="start" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Type18SemiBoldBlack className={classes.title}>Find Florists</Type18SemiBoldBlack>
                <Button
                  autoFocus
                  disableElevation
                  color="inherit"
                  onClick={handleClear}
                  className={classes.clear}
                >
                  clear
                </Button>
              </Toolbar>
            </AppBar>
            <Grid className={classes.mobileGrid} container spacing={3}>
              <Grid item xs={12}>
                {ZIPTextField}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {StateComboBox}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {CityComboBox}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {datePicker}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {LocationTypeComboBox}
                </FormControl>
              </Grid>
              {facilityVisible ? (
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    {FacilityTypeComboBox}
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Button
                  className={classes.searchMobile}
                  disableElevation
                  variant="contained"
                  color="primary"
                  id="find-florists-mobile-view"
                  onClick={searchClick}
                  fullWidth
                >
                  <SearchIcon />
                  Find Florists
                </Button>
              </Grid>
            </Grid>
          </Dialog>
        </Grid>
      );
    default:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {ZIPTextField}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              {StateComboBox}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              {CityComboBox}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              {datePicker}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              {LocationTypeComboBox}
            </FormControl>
          </Grid>
          {facilityVisible ? (
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                {FacilityTypeComboBox}
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button
              className={classes.searchDefaultView}
              variant="contained"
              color="primary"
              fullWidth
              id="find-florists-default-view"
              onClick={searchClick}
              disableElevation
            >
              <SearchIcon />
              Find Florists
            </Button>
          </Grid>
        </Grid>
      );
  }
};

export default FindFlorist;

const StyledFormControl = styled(FormControl)`
  & .MuiFilledInput-root {
    background-color: transparent;
  }
`;
